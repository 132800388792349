import { Modal, Button, Tag } from "antd";
import React from "react";
import CheckTable from "./CheckTable";
import { PlusOutlined } from "@ant-design/icons";
import { ModelProps } from "../AutoForm";
import { css } from "@emotion/core";
import { PresetColorTypes } from "antd/lib/_util/colors";

export interface Props<T> {
  title?: string;
  options?: T[];
  columns?: Array<ModelProps<any>>;
  onChange?: (checked: T[]) => void;
  value?: any[];
  dropdownFilter?: boolean;
  valueField?: string;
  labelField?: string | string[];
  mappingField?: string;
  colorfulTags?: boolean;
  tagIcon?: string;
  tagColor?: string;
  modalWidth?: string | number;
  noLabelTxt?: string;
}

export default function CheckTableWithinModal<T>(props: Props<T>) {
  const {
    value,
    title,
    columns,
    options,
    dropdownFilter,
    labelField,
    valueField,
    mappingField,
    colorfulTags,
    tagIcon,
    tagColor,
    modalWidth,
    noLabelTxt,
    ...modal
  } = props;
  const [visible, setVisible] = React.useState(false);

  const onClose = React.useCallback((e: any) => {
    setVisible(false);
  }, []);

  const onAdd = React.useCallback(() => {
    setVisible(true);
  }, []);

  const tags = React.useMemo(
    () =>
      value?.map((v) => {
        const randomElement = () =>
          PresetColorTypes[Math.floor(Math.random() * PresetColorTypes.length)];
        let label = "";
        if (Array.isArray(labelField)) {
          label = v[labelField[0]][labelField[1] ?? "Name"];
        }
        else {
          label = v[labelField as string ?? "Name"];
          if (!label) {
            const o: any = options?.find(
              (o: any) =>
                o[mappingField ?? valueField ?? "ID"] === v[valueField ?? "ID"],
            );
            label = o ? o[labelField ?? "Name"] : noLabelTxt || "No Records";
          }
        }

        return (
          <Tag
            color={
              colorfulTags === true
                ? tagColor
                  ? tagColor
                  : randomElement()
                : ""
            }
            key={v[valueField ?? "ID"]}
            icon={tagIcon}
          >
            {label}
          </Tag>
        );
      }),
    [
      value,
      options,
      labelField,
      valueField,
      mappingField,
      colorfulTags,
      tagIcon,
      tagColor,
      noLabelTxt,
    ],
  );

  return (
    <div css={containerCss}>
      <Modal
        {...modal}
        title={title}
        visible={visible}
        onOk={onClose}
        onCancel={onClose}
        width={modalWidth}
      >
        <CheckTable
          options={options}
          columns={columns}
          value={props.value}
          dropdownFilter={dropdownFilter}
          onChange={props.onChange}
        />
      </Modal>
      <span css={tagsCss}>{tags}</span>
      <Button
        type="dashed"
        icon={<PlusOutlined />}
        onClick={onAdd}
        size="small"
      />
    </div>
  );
}

const containerCss = css`
  text-align: left;
  min-height: 32px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
`;
const tagsCss = css`
  float: "left";
`;
