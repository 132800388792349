export enum PublisherDealStatus {
  NoStatus = 0,
  Expired,
  Applied,
  Rejected,
  Blocked,
  Granted,
}

export const PublisherDealStatusArray = [
  { ID: PublisherDealStatus.Granted, Name: "Granted" },
  { ID: PublisherDealStatus.Blocked, Name: "Blocked" },
  { ID: PublisherDealStatus.Rejected, Name: "Rejected" },
  { ID: PublisherDealStatus.Applied, Name: "Applied" },
  { ID: PublisherDealStatus.NoStatus, Name: "Not Applied" },
];
