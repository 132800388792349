import { load, IReq, QApi, qapi2Url, API } from "./index";
import { Creative } from "../models/Creative";

export const list = (campaignID: number | string, req?: any, api?: QApi): Promise<Creative[]> =>
  load(`campaigns/${campaignID}/creatives${qapi2Url(api)}`, "GET", false, undefined, api);
export const create = (campaignID: number | string, data: Creative): Promise<Creative> =>
  load(`campaigns/${campaignID}/creatives`, "POST", true, data);
export const read = (campaignID: number | string, req: IReq): Promise<Creative> =>
  load(`campaigns/${campaignID}/creatives/${req.ID}`, "GET", true, undefined);
export const update = (campaignID: number | string, req: IReq, data: Creative): Promise<Creative> =>
  load(`campaigns/${campaignID}/creatives/${req.ID}`, "PUT", true, data);
export const del = (campaignID: number | string, req: IReq): Promise<Creative> =>
  load(`campaigns/${campaignID}/creatives/${req.ID}`, "DELETE", true, undefined);
  export const downloadAll = (campaignID: number) =>
  window.open(`${API}campaigns/${campaignID}/creatives/downloadAll`)
