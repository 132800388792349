import BaseModel from "./BaseModel";
import validation from "../../utils/messages/validation";

export interface DeliverySetting extends BaseModel {
  LeadOrderID: number;
  Realtime: boolean;
  Protocol: LeadOrderDeliveryMethod;
  Format: LeadOrderDeliveryFormat;
  URL: string;
  RequestTemplate?: string;
  SuccessTemplate: string;
  Headers: string;
  QParams: string;
  Username: string;
  Password: string;

}

export const LeadOrderDeliveryProps = {
  URL: { rules: [validation.required] },
  Protocol: { rules: [validation.required] },
  Format: { rules: [validation.required] },
  Type: { rules: [validation.required] }
};
export enum LeadOrderDeliveryType {
  RealTime = 1
}
export const LeadOrderDeliveryTypes = [
  { ID: LeadOrderDeliveryType.RealTime, Name: "Real Time" },
];

export enum LeadOrderDeliveryMethod {
  // HTTPPost type
  HTTPPost = 1,
  // HTTPGet type
  HTTPGet,
  //Soap Type
  Soap,
  // Email type
  Email,
  // FTP type
  FTP
}

export const LeadOrderDeliveryMethods = [
  { ID: LeadOrderDeliveryMethod.HTTPGet, Name: "HTTP Get" },
  { ID: LeadOrderDeliveryMethod.HTTPPost, Name: "HTTP Post" },
  { ID: LeadOrderDeliveryMethod.FTP, Name: "FTP" },
  { ID: LeadOrderDeliveryMethod.Email, Name: "Email" },
];

export enum LeadOrderDeliveryFormat {
  // CSV type
  CSV = 1,
  // HTML type
  HTML,
  // JSON type
  JSON,
  // QUERY Type
  QUERY,
  // XML type
  XML,
  // FORM type
  FORM,
  // JSONTemplate type
  JSONTemplate
}

export const LeadOrderDeliveryFormats = [
  { ID: LeadOrderDeliveryFormat.CSV, Name: "CSV" },
  { ID: LeadOrderDeliveryFormat.HTML, Name: "HTML" },
  { ID: LeadOrderDeliveryFormat.JSON, Name: "JSON" },
  { ID: LeadOrderDeliveryFormat.JSONTemplate, Name: "JSON Template" },
  { ID: LeadOrderDeliveryFormat.QUERY, Name: "QUERY Params" },
  { ID: LeadOrderDeliveryFormat.XML, Name: "XML" },
  { ID: LeadOrderDeliveryFormat.FORM, Name: "FORM" },
];

