import { load, IReq, QApi, qapi2Url } from "./index";
import { PublisherDeal } from "../models/PublisherDeal";
import { GenModel } from "../models/GenModel";
import { Creative } from "../models/Creative";
import { LandingPage } from "../models/LandingPage";

export const list = (campaignID: number | string, req?: any, api?: QApi): Promise<PublisherDeal[]> =>
  load(`campaigns/${campaignID}/publisherDeals${qapi2Url(api)}`, "GET", false, undefined, api);
export const create = (campaignID: number | string, data: PublisherDeal): Promise<PublisherDeal> => {
  data.CampaignID = +campaignID;
  return load(`campaigns/${campaignID}/publisherDeals`, "POST", true, data);
}
export const read = (campaignID: number | string, req: IReq): Promise<PublisherDeal> =>
  load(`campaigns/${campaignID}/publisherDeals/${req.ID}`, "GET", true, undefined);
export const update = (campaignID: number | string, req: IReq, data: PublisherDeal): Promise<PublisherDeal> => {
  data.CampaignID = +campaignID;
  return load(`campaigns/${campaignID}/publisherDeals/${req.ID}`, "PUT", true, data);
}
export const del = (campaignID: number | string, req: IReq): Promise<PublisherDeal> =>
  load(`campaigns/${campaignID}/publisherDeals/${req.ID}`, "DELETE", true, undefined);
export const join = (campaignID: number | string): Promise<PublisherDeal> =>
  load(`campaigns/${campaignID}/publisherDeals/join`, "GET", false, undefined);

export const publisherDealCount = (campaignID: number): Promise<number> =>
  load(`campaigns/${campaignID}/publisherDeals/count`, "GET", true, undefined);

export const publisherDealStatus = (req?: any, api?: QApi): Promise<GenModel[]> =>
  load(`campaigns/publisherDeal/status${qapi2Url(api)}`, "GET", false, undefined, api);

export const getWithPublisher = (campaignID: number | string, req?: any, api?: QApi): Promise<PublisherDeal[]> =>
  load(`campaigns/${campaignID}/publisherDeals/withPublisher${qapi2Url(api)}`, "GET", false, undefined, api);

export const updatePartial = (campaignID: number | string, req: IReq, data: Partial<PublisherDeal>): Promise<any> =>
  load(`campaigns/${campaignID}/publisherDeals/${req.ID}`, "PATCH", true, data);

export const publisherDealCreatives = (publisherDealID: number, req?: any, api?: QApi): Promise<Creative[]> =>
  load(`campaigns/publisherDeal/${publisherDealID}/creatives${qapi2Url(api)}`, "GET", false, undefined, api);
export const publisherDealLandingPages = (publisherDealID: number, req?: any, api?: QApi): Promise<LandingPage[]> =>
  load(`campaigns/publisherDeal/${publisherDealID}/landingPages${qapi2Url(api)}`, "GET", false, undefined, api);