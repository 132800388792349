import { load, IReq, QApi, qapi2Url, API } from "./index";
import { Lead } from "../models/Lead";
import { History } from "../models/History";
import { PayoutExceptionHistory } from "../models/PayoutExceptionHistory";
export const list = (req?: any, api?: QApi): Promise<Lead[]> =>
  load(`leads${qapi2Url(api)}`, "GET", false, undefined, api);
export const create = (data: Lead): Promise<Lead> =>
  load(`leads`, "POST", true, data);
export const read = (req: IReq): Promise<Lead> =>
  load(`leads/${req.ID}`, "GET", true, undefined);
export const update = (req: IReq, data: Lead): Promise<Lead> =>
  load(`leads/${req.ID}`, "PUT", true, data);
export const del = (req: IReq): Promise<Lead> =>
  load(`leads/${req.ID}`, "DELETE", true, undefined);

export const rejectedLeads = (req?: any, api?: QApi): Promise<Lead[]> =>
  load(`leads/rejected${qapi2Url(api)}`, "GET", false, undefined, api);
export const exportRejectedLeads = (req?: any, api?: QApi) =>
  window.location.assign(`${API}leads/rejected/export/${req.type}${qapi2Url(api)}`);
export const exportLeads = (req?: any, api?: QApi) =>
  window.location.assign(`${API}leads/export/${req.suffix}/${req.type}${qapi2Url(api)}`);
export const getReturns = (partner: string, req?: any, api?: QApi): Promise<Lead[]> =>
  load(`leads/returns/${partner}${qapi2Url(api)}`, "GET", false, undefined, api);
export const uploadReturnList = (data: any): Promise<string> =>
  load(`leads/returns/upload`, "POST", true, data);
export const processReturns = (data: any): Promise<Lead> =>
  load(`leads/returns/process`, "POST", true, data);
export const updatePartial = (req: IReq, data: Partial<Lead>): Promise<any> =>
  load(`leads/${req.ID}`, "PATCH", true, data);

export const repostLeads = (data: Lead[]): Promise<Lead> =>
  load(`processor/repost`, "POST", true, data);

export const uploadLeads = (data: any): Promise<Lead[]> =>
  load(`transfer/upload`, "POST", true, data);

export const getUnsolds = (req?: any, api?: QApi): Promise<Lead[]> =>
  load(`leads/unsold${qapi2Url(api)}`, "GET", false, undefined, api);

export const getFlaggeds = (req?: any, api?: QApi): Promise<Lead[]> =>
  load(`leads/flagged${qapi2Url(api)}`, "GET", false, undefined, api);

export const getLeadHistory = (leadID: number, req?: IReq, api?: QApi): Promise<History[]> =>
  load(`leads/${leadID}/history${qapi2Url(api)}`, "GET", false, undefined, api);
export const updateData = (req: IReq, data: Partial<Lead>): Promise<any> =>
  load(`leads/${req.ID}/updateData`, "PATCH", true, data);

export const getPayoutExceptionsHistory = (leadID: number, req?: IReq, api?: QApi): Promise<PayoutExceptionHistory[]> =>
  load(`leads/${leadID}/payoutExceptions${qapi2Url(api)}`, "GET", false, undefined, api);
export const getPublisherLeads = (campaignID: number, req?: any, api?: QApi): Promise<Lead[]> =>
  load(`leads/campaign/${campaignID}/publisher${qapi2Url(api)}`, "GET", false, undefined, api);
export const getPublisherReturnedLeads = (req?: any, api?: QApi): Promise<Lead[]> =>
  load(`leads/returns/publisherReturns${qapi2Url(api)}`, "GET", false, undefined, api);

  export const bulkCommentLeads = (data: any): Promise<any> =>
  load(`leads/returns/bulkComment`, "POST", true, data);

  export const exportPublisherReturnedLeads = (req?: any, api?: QApi) =>
  window.location.assign(`${API}leads/returns/publisher/export/${req.type}${qapi2Url(api)}`);
  export const exportPublisherLeads = (req?: any, api?: QApi) =>
  window.location.assign(`${API}leads/campaign/${req.campaignID}/publisher/export/${req.type}${qapi2Url(api)}`);
  export const getData = (req: IReq): Promise<any[]> =>
  load(`leads/${req.ID}/data`, "GET", true, undefined);


