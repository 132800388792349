import { DataSource } from "./index";
import { list, read } from "../api/leads";
import { Lead } from "../models/Lead";
export const LeadDS: DataSource<Lead> = {
  list,
  create: () => { throw "Not implemented"; }, //eslint-disable-line
  read,
  update: () => { throw "Not implemented"; }, //eslint-disable-line
  del: () => { throw "Not implemented"; }, //eslint-disable-line
};

export default LeadDS;
