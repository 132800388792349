import React from "react";
import { Input, Form } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { PartnerProps } from "../../../data";

const Step1 = React.forwardRef((props: any, ref: any) => {
  return (
    <React.Fragment>
      <Form
        form={props.form}
        style={{
          textAlign: "center",
          width: "40%",
          margin: "auto",
          marginTop: 32,
        }}
      >
        <Form.Item
          name="Email"
          hasFeedback
          rules={PartnerProps.Email.rules}
        >
          <Input
            placeholder="Email"
            type="email"
            size="large"
            prefix={<UserOutlined />}
          />
        </Form.Item>
        <Form.Item
          name="Password"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Password is required.",
            },
          ]}
        >
          <Input.Password
            autoComplete="off"
            type="password"
            placeholder="Password"
            size="large"
            prefix={<LockOutlined />}
          />
        </Form.Item>
        <Form.Item
          name="PasswordAgain"
          dependencies={["Password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Password is required.",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("Password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("Passwords doesn't match.");
              },
            }),
          ]}
        >
          <Input.Password
            autoComplete="off"
            type="password"
            size="large"
            placeholder="Confirm Password"
            prefix={<LockOutlined />}
          />
        </Form.Item>

        <Form.Item>
          <Link to="/login" style={{ float: "right", fontWeight: "bold" }}>
            Already registered ?
          </Link>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
});

export default Step1;
