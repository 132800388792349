import validation from "../../utils/messages/validation";
import BaseModel from "./BaseModel";

export interface LandingPage extends BaseModel {
  Name: string;
  Domain: string;
  Path: string;
  Description: string;
  CampaignID: number;
  IsActive: boolean;

}
export const LandingPageProps = {
  Name: { rules: [validation.required, validation.max(50)], },
  Domain: { rules: [ validation.max(10000)] },
  Path: { rules: [validation.required, validation.max(200)] },
  Description: { rules: [validation.required, validation.minMax(5, 300)] },
};
