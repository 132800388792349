import validation from "../../utils/messages/validation";
import BaseModel from "./BaseModel";
import { CampaignType } from "./CampaignType";
import { Publisher } from "./Publisher";
import { PublisherDealLandingPage } from "./PublisherDealLandingPage";
import { PublisherDealStatus } from "./PublisherDealStatus";
import { SuppressionList } from "./SuppressionList";
import { Tag } from "./Tag";

export interface PublisherDeal extends BaseModel {
  PublisherID: number,
  CampaignID: number;
  Payout: number;
  Pixel: string;
  RedirectTraffic: string;
  Status: PublisherDealStatus;
  LandingPages: PublisherDealLandingPage[];
  SuppressionLists: SuppressionList[];
  Publisher: Publisher;
  URL: string;
  UniquePath: string;
  Type: CampaignType;
}

export interface PublisherDealCampaignDto {
  ID: number,
  Name: string,
  CategoryID: number,
  Status: PublisherDealStatus;
  Payout: number;
  End: string,
  Description: string,
  PublisherDealID: number,
  Tags: Tag[],
  Url: string,
  Restricted: boolean,
  SuppressionLists: SuppressionList[],
  PublisherID: number;
  CampaignType: CampaignType
}

export const PublisherDealProps = {
  CampaignID: { rules: [validation.required] },
  PublisherID: { rules: [validation.required] },
  Pixel: { rules: [validation.max(1024)] },
  Type: { rules: [validation.required] },
};

