import { DataSource } from "./index";
import { getUnsolds as list } from "../api/leads";
import {  Lead } from "../models/Lead";
export const UnsoldLeadDS: DataSource<Lead> = {
  list,
  create: () => { throw "Not implemented"; }, //eslint-disable-line
  read: () => { throw "Not implemented"; }, //eslint-disable-line
  update: () => { throw "Not implemented"; }, //eslint-disable-line
  del: () => { throw "Not implemented"; }, //eslint-disable-line
};

export default UnsoldLeadDS;
