import React from "react";
import isEqual from "react-fast-compare";

import { Menu } from "antd";
import { MenuProps } from "antd/lib/menu";
import { Menu as MenuModel } from "../../data/models/Menu";
import { splitPermitations } from "../../utils/collections/strings";
import { getMenuIcon } from "../../utils/getters/menuIcon";

interface Props extends MenuProps {
  data: MenuModel[];
  path: string;
  collapsed: boolean;
}

export const SideMenu = React.memo((props: Props): JSX.Element => {
  const { data, path, ...rest } = props;
  if (props.data.length === 0) {
    rest["defaultOpenKeys"] = splitPermitations(props.path).map(
      (key: string) => `/${key}`,
    );
  }
  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={["0"]}
      {...rest}
      style={{ height: "calc(100vh - 84px)", overflowY: "auto" }}
    >
      {data.map(render)}
    </Menu>
  );
}, areEqual);

function render(item: MenuModel): JSX.Element {
  return item.Children && item.Children.length
    ? renderSubMenu(item)
    : renderItem(item);
}

function renderSubMenu(item: MenuModel): JSX.Element {
  let items: JSX.Element[] = [];
  if (item.Children) {
    items = item.Children.map(render);
  }
  return (
    <Menu.SubMenu
      key={item.Path}
      title={
        <span>
          {getMenuIcon(item.Icon)}
          <span>{item.Name}</span>
        </span>
      }
    >
      {items}
    </Menu.SubMenu>
  );
}

function renderItem(item: MenuModel): JSX.Element {
  return (
    <Menu.Item key={item.Path} title={item.Name}>
      {getMenuIcon(item.Icon)}
      <span>{item.Name}</span>
    </Menu.Item>
  );
}

function areEqual(p: Props, n: Props) {
  if (p.collapsed !== n.collapsed) return false;
  if (p.path !== n.path) return false;
  if (!isEqual(p.selectedKeys, n.selectedKeys)) return false;
  return isEqual(p.data, n.data);
}

export default SideMenu;
