import validation from "../../utils/messages/validation";
import { LandingPage } from "./LandingPage";

export interface Creative {
  ID: number;
  Name: string;
  Type: number;
  CampaignID: number;
  Data: string;
  LandingPages: LandingPage[];
  IsActive:boolean;
}

export interface EmailTemplateData {
  SubjectLines: string;
  FromLines: string;
}
export const CreativeProps = {
  Name: {
    rules: [validation.required, validation.minMax(3, 255)],
  },
  Type: {
    rules: [validation.required],
  },
};
