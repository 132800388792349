import { DataSource } from ".";
import { list, create, read, update, del } from "../api/campaignLandingPages";
import { LandingPage } from "../models/LandingPage";
export const CampaignLandingPagesDS = (campaignID: string | number): DataSource<LandingPage> => ({
  list: list.bind(undefined, campaignID),
  create: create.bind(undefined, campaignID),
  read: read.bind(undefined, campaignID),
  update: update.bind(undefined, campaignID),
  del: del.bind(undefined, campaignID),
});

export default CampaignLandingPagesDS;

