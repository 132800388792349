import { load, IReq, QApi, qapi2Url } from "./index";
import { Category } from "../models/Category";

interface IChildReq extends IReq {
  childID: number;
}

export const tree = (): Promise<Category[]> =>
  load(`categories/tree`, "GET", true, undefined);

export const list = (req?: any, api?: QApi): Promise<Category[]> =>
  load(`categories${qapi2Url(api)}`, "GET", true, undefined, api);
export const create = (data: Category): Promise<Category> =>
  load(`categories`, "POST", false, data);
export const read = (req: IReq): Promise<Category> =>
  load(`categories/${req.ID}`, "GET", true, undefined);
export const update = (req: IReq, data: Category): Promise<Category> =>
  load(`categories/${req.ID}`, "PUT", true, data);
export const del = (req: IReq): Promise<Category> =>
  load(`categories/${req.ID}`, "DELETE", true, undefined);

export const listChildren = (req: IChildReq): Promise<Category[]> =>
  load(`categories/${req.ID}/children`, "GET", true, undefined);
export const createChildren = (req: IChildReq): Promise<Category> =>
  load(
    `categories/${req.ID}/children/${req.childID}`,
    "POST",
    true,
    undefined,
  );
export const readChildren = (req: IChildReq): Promise<Category> =>
  load(
    `categories/${req.ID}/children/${req.childID}`,
    "GET",
    true,
    undefined,
  );
export const delChildren = (req: IChildReq): Promise<Category> =>
  load(
    `categories/${req.ID}/children/${req.childID}`,
    "DELETE",
    true,
    undefined,
  );
