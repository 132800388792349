import { LeadOrder } from "./LeadOrder";

export interface TransferLog {
  ID: number;
  CreatedAt: Date;
  Status: TransferLogStatus;
  LeadOrderID: number;
  LeadOrder: LeadOrder;
  LeadID: number;
  DeliverySettingID: number;
  CampaignID: number;
  Response: string;
  Data: object;
  Error: string;
  PostURL: string;
}

export enum TransferLogStatus {
  success = 1,
  fail = 2,
}

export const TransferLogStatusses = [
  {ID: 1, Name: "Success"},
  {ID: 2, Name: "Fail"},
];
