import validation from "../../utils/messages/validation";
import BaseModel from "./BaseModel";

export interface User extends BaseModel {
  Username: string;
  Password: string;
  IsActive: boolean;
  FailCount: number;
  LastLogin: string;
  RoleID: number;
  FirstName: string;
  LastName: string;
  Phone: string;
  PartnerID: number;
}

export const UserProps = {
  Username: {
    rules: [
      validation.required,
      validation.minMax(6, 50),
      validation.type("email"),
    ],
  },
  RoleID: {
    rules: [validation.required],
  },
  FirstName: {
    rules: [validation.max(100), validation.required],
  },
  LastName: {
    rules: [validation.max(100), validation.required],
  },
};
