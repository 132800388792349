import { load, IReq, QApi, qapi2Url } from "./index";
import { EmailTemplate } from "../models/EmailTemplate";

export const list = (req?: any, api?: QApi): Promise<EmailTemplate[]> =>
  load(`emailTemplates${qapi2Url(api)}`, "GET", false, undefined);
export const create = (data: EmailTemplate): Promise<EmailTemplate> =>
  load(`emailTemplates`, "POST", true, data);
export const read = (req: IReq): Promise<EmailTemplate> =>
  load(`emailTemplates/${req.ID}`, "GET", true, undefined);
export const update = (
  req: IReq,
  data: EmailTemplate,
): Promise<EmailTemplate> =>
  load(`emailTemplates/${req.ID}`, "PUT", true, data);
export const del = (req: IReq): Promise<EmailTemplate> =>
  load(`emailTemplates/${req.ID}`, "DELETE", true, undefined);
