import { DataSource } from ".";
import { list, create, read, update, del } from "../api/buyers";
import { Buyer } from "../models/Buyer";
export const BuyersDS: DataSource<Buyer> = {
  list,
  create,
  read,
  update,
  del,
};

export default BuyersDS;
