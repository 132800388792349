
import { DataSource } from ".";
import { list, read, update, create, del } from "../api/payoutException";
import { PayoutException } from "../models/PayoutException";

export const PublisherDealPayoutExceptionDS = (campaignID: number, publisherID: number): DataSource<PayoutException> => ({
  list: list.bind(undefined, campaignID, publisherID),
  create: create.bind(undefined, campaignID, publisherID),
  update: update.bind(undefined, campaignID, publisherID),
  del: del.bind(undefined, campaignID, publisherID),
  read: read.bind(undefined, campaignID, publisherID),
});


export default PublisherDealPayoutExceptionDS;
