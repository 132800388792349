export function splitPermitations(value: string): string[] {
  const values = [];
  const items = value.split("/");
  for (let i = 0; i < items.length; i++) {
    const it = [];
    for (let j = 0; j < items.length - i; j++) {
      if (items[j].length > 0) {
        it.push(items[j]);
      }
    }
    if (it.length > 0) {
      values.push(it.join("/"));
    }
  }
  return values;
}
