import React from "react";
import css from "@emotion/css";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

interface Props {
  collapsed: boolean;
  onClick: () => void;
}

export const MenuTrigger = React.memo((props: Props) => {
  let icon;
  if (props.collapsed) {
    icon = <MenuUnfoldOutlined onClick={props.onClick} />;
  } else {
    icon = <MenuFoldOutlined onClick={props.onClick} />;
  }

  return <span css={containerCss}>{icon}</span>;
});

const containerCss = css`
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: #ab1702;
  }
  margin-right: 16px;
`;
