import validation from "../../utils/messages/validation";
import { Partner } from "./Partner";

export interface Buyer extends Partner {
  PublisherID: number;
  Password: string;
  PasswordAgain: string;
}

export const BuyerProps = {
  Email: {
    rules: [
      validation.required,
      validation.pattern(
        "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
        "Email needs to be valid.",
      ),
    ],
  },
};
