import BaseModel from "./BaseModel";
import { FieldType } from "./FieldType";
import validation from "../../utils/messages/validation";

export interface MappingField extends BaseModel {
  LeadOrderID: number;
  FieldID: number;
  Name: string;
  Type: FieldType;
  MaxLength?: number;
  MinLength?: number;
  Default: string;
  Format: string;
}


export const LeadOrderMappingFieldProps = {
  Name: { rules: [validation.required, validation.minMax(1, 50)] },
  Type: { rules: [validation.required] },
  Default: { rules: [validation.max(100)] },
  FieldID: { rules: [validation.required] },
};

export const MappingFieldFormatArray = [
  { ID: "02/01/2006", Name: "DD/MM/YYYY" },
  { ID: "01/02/2006", Name: "MM/DD/YYYY" },
  { ID: "2006-01-02 15:04:05", Name: "YYYY-MM-DD HH:MM:SS" },
  { ID: "01/02/2006 15:04:05", Name: "MM/DD/YYYY HH:MM:SS" },
  { ID: "2006-01-02", Name: "YYYY-MM-DD" },
];


