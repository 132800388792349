import { DataSource } from ".";
import { list, create, read, update, del } from "../api/publishers";
import { Publisher } from "../models/Publisher";
export const PublishersDS: DataSource<Publisher> = {
  list,
  create,
  read,
  update,
  del,
};

export default PublishersDS;
