import React, { useContext } from "react";
import Button, { ButtonProps } from "antd/lib/button";
import { LanguageContext } from "../../contexts/LanguageContext";
import { getWithFlatKey } from "../../utils/collections/maps";
import isEqual from "react-fast-compare";

export interface FormButtonProps extends ButtonProps {
  value: any;
  valueField?: string;
  labelField?: string;
  defaultLabel?: string;
}

/**
 * Creates an primary `Button` as defined in {@link https://ant.design/docs/spec/buttons AntD Button}
 *
 * @param value - Value is required for innerHTML
 * @param valueField - valueField defines key-value - optional
 * @param labelField - labelField can be given with prop for automatically getting dataIndexes
 * @param defaultLabel - defaultLabel is default value for button
 *
 * @public
 */

const FormButton = (props: FormButtonProps) => {
  const { value, defaultLabel, labelField } = props;
  const languageContext: any = useContext(LanguageContext);
  let label = defaultLabel || languageContext.select;
  if (value) {
    if (labelField) {
      if (labelField.indexOf(".") >= 0) {
        label = getWithFlatKey(value, labelField);
      } else {
        label = value[labelField];
      }
    } else {
      label = value;
    }
  }
  return (
    <Button style={{ width: "100%" }} {...props}>
      {label}
    </Button>
  );
};

export default React.memo(FormButton, isEqual);
