import { DataSource } from ".";
import { publisherCampaigns as list, create,read,update,del  } from "../api/campaigns";
import { Campaign } from "../models/Campaign";
export const PublisherCampaignsDS: DataSource<Campaign> = {
  list,
  create,
  read,
  update,
  del,
};

export default PublisherCampaignsDS;
