import validation from "../../utils/messages/validation";
import { AltRedirectURL } from "./AltRedirectURL";
import { CampaignStatus } from "./CampaignStatus";
import { CampaignType } from "./CampaignType";
import { Field } from "./Field";
import { Region } from "./Region";
import { Tag } from "./Tag";
import { Validation } from "./Validation";

export interface Campaign {
  ID: number;
  Name: string;
  Description: string;
  CategoryID: string;
  Type: CampaignType;
  Start: string;
  End: string;
  Restricted: string;
  Regions: Region[];
  AltRedirectURLs: AltRedirectURL[];
  Tags: Tag[];
  SuppressionFields: Field[];
  Fields: Field[];
  Validations: Validation[];
  ServerSidePixel: string;
  Status: CampaignStatus;
  UniqueID: string;
  Budget: number;
  BudgetUsed: number;
  BudgetReached: number;
  Hidden: boolean;
  NotifyLimitPercentage: number;
  ExternalWebForm: boolean;
  FromLines: string;
  SubjectLines: string;
}

export const CampaignProps = {
  Name: {
    rules: [validation.required, validation.minMax(2, 80)],
  },
  Description: {
    rules: [validation.max(300)],
  },
  CategoryID: {
    rules: [validation.required],
  },
  Start: {
    rules: [validation.required],
  },
  Restricted: {
    rules: [validation.required],
  },
  SuppressionFields: {
    rules: [validation.required],
  },
  IsActive: {
    rules: [validation.required],
  },
};
