import { DataSource } from ".";
import { list, create, read, update, del } from "../api/fields";
import { Field } from "../models/Field";
export const FieldsDS: DataSource<Field> = {
  list,
  create,
  read,
  update,
  del,
};

export default FieldsDS;
