import React from "react";
import Upload, { UploadProps } from "antd/lib/upload";
import { Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { LanguageContext } from "../../../contexts/LanguageContext";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { findByAttr } from "../../../utils/collections/arrays";
import isEqual from "react-fast-compare";
import { API } from "../../../data/api";

interface Props extends UploadProps {
  fileLimit?: number;

}

const UploadInput = (props: Props) => {
  const { fileLimit, defaultFileList, onChange, accept, ...rest } = props;
  const fSize = props.defaultFileList
    ? props.defaultFileList.length
    : props.fileList?.length;

  const [fileSize, setFileSize] = React.useState(fSize ?? 0);
  const [uploading, setUploading] = React.useState(false);
  React.useEffect(() => {
    setFileSize(fSize ?? 0);
  }, [fSize, setFileSize]);

  const showButton = (fileLimit || 100) > fileSize;
  const languageContext: any = React.useContext(LanguageContext);

  const onComplete = React.useCallback(({ file, fileList, event }: UploadChangeParam) => {
    if (file.status === "uploading") {
      setUploading(true);
    } else if (file.status === "done") {
      const f = findByAttr(fileList, "uid", file.uid);
      f.url = `upload/temp/${file.response}`;
      f.thumbUrl = `upload/temp/${file.response}`;
      f.linkProps = ["download", "image"];
      f.name = file.response;
      onChange && onChange(f.name);
      setUploading(false);
    } else {
      setUploading(false);
    }
  }, [onChange]);


  const beforeUpload = React.useCallback((file: UploadFile) => {
    const allowed = accept?.includes(file.type!) ?? true;
    if (!allowed)
      message.error(`Please select a file with accepted type: ${accept}`);
    return accept?.includes(file.type!) ?? true;
  }, [accept]);

  return (
    <React.Fragment>
      <Upload {...rest} accept={accept} beforeUpload={beforeUpload} onChange={onComplete}>
        {showButton && (
          <Button block={true} loading={uploading}>
            <UploadOutlined />
            {languageContext.selectOrDrag}
          </Button>
        )}
      </Upload>
      {(fileLimit ?? 5) > 1 && (<p>
        <small>Maximum file limit: {fileLimit}</small>
      </p>)}
    </React.Fragment>
  );
};

UploadInput.defaultProps = {
  action: `${API}upload/temp`,
  fileLimit: 5,
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  },
  listType: "picture",
};

export default React.memo(UploadInput, isEqual);
