import { load, IReq, QApi, qapi2Url, API } from "./index";
import { SuppressionList } from "../models/SuppressionList";

export const list = (req?: any, api?: QApi): Promise<SuppressionList[]> =>
  load(`suppressionLists${qapi2Url(api)}`, "GET", false, undefined);
export const create = (data: SuppressionList): Promise<SuppressionList> =>
  load(`suppressionLists`, "POST", true, data);
export const read = (req: IReq): Promise<SuppressionList> =>
  load(`suppressionLists/${req.ID}`, "GET", true, undefined);
export const update = (
  req: IReq,
  data: SuppressionList,
): Promise<SuppressionList> =>
  load(`suppressionLists/${req.ID}`, "PUT", true, data);
export const del = (req: IReq): Promise<SuppressionList> =>
  load(`suppressionLists/${req.ID}`, "DELETE", true, undefined);


export const itemsCount = (id: number): Promise<number> =>
  load(`suppressionLists/${id}/items/count`, "GET", true, undefined);

export const download = (id: number) =>
  window.location.assign(`${API}suppressionLists/${id}/download`);