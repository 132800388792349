import React, { useCallback } from "react";
import Input, { InputProps } from "antd/lib/input";
import isEqual from "react-fast-compare";

export interface Props extends InputProps {
  mask?: RegExp;
}

/**
 * Creates an `Input` as defined in {@link https://ant.design/components/input/ AntD Input} which masks itself with a given RegExp
 *
 * @param mask - RegExp for masking
 * @param onChange - onChange method for handling change for value
 *
 * @public
 */
const MaskedInput = (props: Props) => {
  const { mask, onChange } = props;
  const maskFunc = useCallback(
    (e: any) => {
      const { value } = e.target;
      if (mask) {
        const m = mask.exec(value);
        if (m && m[0] !== value) return;
      }
      if (onChange) onChange(value);
    },
    [mask, onChange],
  );
  return <Input {...props} onChange={maskFunc} />;
};

export default React.memo(MaskedInput, isEqual);
