import React from "react";
import css from "@emotion/css";
import { Layout } from "antd";

export const Header = (props: any) => (
  <Layout.Header css={containerCss}>{props.children}</Layout.Header>
);

const containerCss = css`
  background: #00000000 !important;
  margin: 0 16px !important;
  padding: 0 !important;
`;
