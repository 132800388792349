import { load, IReq, QApi, qapi2Url } from "./index";
import { DataEntry } from "../models/DataEntry";
import { PublisherDeal } from "../models/PublisherDeal";

export const list = (req?: any, api?: QApi): Promise<DataEntry[]> =>
  load(`dataEntry${qapi2Url(api)}`, "GET", false, undefined, api);
export const create = (data: DataEntry): Promise<DataEntry> =>
  load(`dataEntry`, "POST", true, data);
export const read = (req: IReq): Promise<DataEntry> =>
  load(`dataEntry/${req.ID}`, "GET", true, undefined);
export const update = (req: IReq, data: DataEntry): Promise<DataEntry> =>
  load(`dataEntry/${req.ID}`, "PUT", true, data);
export const del = (req: IReq): Promise<DataEntry> =>
  load(`dataEntry/${req.ID}`, "DELETE", true, undefined);
  export const publishers = (campaignID: number | string, req?: any, api?: QApi): Promise<PublisherDeal[]> =>
  load(`dataEntry/campaign/${campaignID}/publishers${qapi2Url(api)}`, "GET", false, undefined, api);
  export const getDataEntry = (token:string,req: IReq): Promise<DataEntry> =>
  load(`public/dataEntryForm/${req.ID}/token/${token}`, "GET", true, undefined);

