import { load, IReq, QApi, qapi2Url } from "./index";
import { LandingPage } from "../models/LandingPage";

export const list = (campaignID: number | string, req?: any, api?: QApi): Promise<LandingPage[]> =>
  load(`campaigns/${campaignID}/landingPages${qapi2Url(api)}`, "GET", false, undefined, api);
export const create = (campaignID: number | string, data: LandingPage): Promise<LandingPage> =>
  load(`campaigns/${campaignID}/landingPages`, "POST", true, data);
export const read = (campaignID: number | string, req: IReq): Promise<LandingPage> =>
  load(`campaigns/${campaignID}/landingPages/${req.ID}`, "GET", true, undefined);
export const update = (campaignID: number | string, req: IReq, data: LandingPage): Promise<LandingPage> =>
  load(`campaigns/${campaignID}/landingPages/${req.ID}`, "PUT", true, data);
export const del = (campaignID: number | string, req: IReq): Promise<LandingPage> =>
  load(`campaigns/${campaignID}/landingPages/${req.ID}`, "DELETE", true, undefined);
