import { load, IReq, QApi, qapi2Url } from "./index";
import { Tag } from "../models/Tag";

export const list = (req?: any, api?: QApi): Promise<Tag[]> =>
  load(`tags${qapi2Url(api)}`, "GET", false, undefined, api);
export const create = (data: Tag): Promise<Tag> =>
  load(`tags`, "POST", true, data);
export const read = (req: IReq): Promise<Tag> =>
  load(`tags/${req.ID}`, "GET", true, undefined);
export const update = (req: IReq, data: Tag): Promise<Tag> =>
  load(`tags/${req.ID}`, "PUT", true, data);
export const del = (req: IReq): Promise<Tag> =>
  load(`tags/${req.ID}`, "DELETE", true, undefined);


export const campaigns = (id: number): Promise<number> =>
  load(`tags/${id}/campaigns`, "GET", true, undefined);
export const leads = (id: number): Promise<number> =>
  load(`tags/${id}/leads`, "GET", true, undefined);
