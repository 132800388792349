import validation from "../../utils/messages/validation";
import BaseModel from "./BaseModel"

export interface PayoutException extends BaseModel {
  Type: PayoutType;
  //if type LeadOrder Value represents LeadOrderID, Creative CreativeID etc..
  Value: number;
  Payout: number;
  PublisherDealID: number;
}

export enum PayoutType {
  LeadOrder = 1,
  Creative,
  SubID,
}
export const PayoutExceptionProps = {
  Type: { rules: [validation.required] },
  Value: { rules: [validation.required] },
  Payout: { rules: [validation.required] },
};

export const PayoutExceptionTypeArray = [
  { ID: PayoutType.LeadOrder, Name: "Lead Order" },
  { ID: PayoutType.Creative, Name: "Creative" },
  { ID: PayoutType.SubID, Name: "SubID" },

]