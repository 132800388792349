import { DataSource } from ".";
import { list, create, read, update, del } from "../api/leadOrdersMappingFields";
import { MappingField } from "../models/MappingField";
export const LeadOrderMappingFieldsDS = (leadOrderID: string | number): DataSource<MappingField> => ({
  list: list.bind(undefined, leadOrderID),
  create: create.bind(undefined, leadOrderID),
  read: read.bind(undefined, leadOrderID),
  update: update.bind(undefined, leadOrderID),
  del: del.bind(undefined, leadOrderID),
});

export default LeadOrderMappingFieldsDS;

