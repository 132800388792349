import { DataSource } from "./index";
import { list, create, read, update, del } from "../api/leadOrders";
import { LeadOrder } from "../models/LeadOrder";
export const LeadOrderDS: DataSource<LeadOrder> = {
  list,
  create,
  read,
  update,
  del,
};

export default LeadOrderDS;
