export function getCookie(name: string) {
  if (document) {
    const cookie = document.cookie
      .split(";")
      .filter(item => item.indexOf(`${name}=`) >= 0);
    if (cookie && cookie.length > 0) {
      const val = cookie[0];
      return val.substring(val.indexOf("=") + 1);
    }
    return undefined;
  }
  return undefined;
}

export function setCookie(name: string, value: string) {
  return (document.cookie = `${name}=${value}`);
}

export function removeCookie(name: string) {
  return (document.cookie = `${name}=;  expires=Thu, 01 Jan 1970 00:00:00 UTC;`);
}

export function removeCookies(names: string[]) {
  names.forEach((val: string) => {
    document.cookie = `${val}=;  expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  });
}

export function removeAllCookies() {
  const cookies = document.cookie.split(";");

  for (const cookie of cookies) {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC";
  }
}

export function haveToken() {
  return (
    getCookie("loggedin") !== undefined && getCookie("loggedin") === "true"
  );
}
