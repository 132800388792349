import { DataSource } from ".";
import { list, create, read, update, del } from "../api/tags";
import { Tag } from "../models/Tag";
export const TagsDS: DataSource<Tag> = {
  list,
  create,
  read,
  update,
  del,
};

export default TagsDS;
