import { DataSource } from ".";
import { publisherSubscribedCampaigns as list, create, read, update, del, publisherSubscribedCampaignsById } from "../api/campaigns";
import { Campaign } from "../models/Campaign";
export const PublisherSubscribedCampaignsDS: DataSource<Campaign> = {
  list,
  create,
  read,
  update,
  del,
};

export const PublisherSubscribedCampaignsByIDDS = (publisherID: string | number): DataSource<Campaign> => ({
  list: publisherSubscribedCampaignsById.bind(undefined, publisherID),
  create,
  read,
  update,
  del,
});

export default PublisherSubscribedCampaignsDS;
