import validation from "../../utils/messages/validation";
import BaseModel from "./BaseModel";

export interface Menu extends BaseModel {
  Icon: string;
  Name: string;
  Path: string;
  Children?: Menu[];
  ParentId: number;
}
export const MenuProps = {
  Name: {
    rules: [validation.required, validation.minMax(3, 20)],
  },
  Icon: {
    rules: [validation.minMax(2, 30)],
  },
};
