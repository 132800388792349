import React from "react";
import { Input, Form } from "antd";
import Captcha from "../../../components/form/inputs/Captcha";
import { FormInstance } from "antd/lib/form";
interface Props {
  setSubmitable: (
    recaptchaLoaded: boolean,
    recaptchaValid: boolean,
    recaptchaResponseKey: string,
  ) => void;
  form: FormInstance;
}

const Step4 = React.forwardRef((props: Props, ref: any) => {
  const recaptchaRef = React.useRef<any>(null);

  const verifyCallback = React.useCallback(
    (responseKey: string) => {
      if (props.setSubmitable) {
        props.setSubmitable(true, true, responseKey);
      }
    },
    [props],
  );

  return (
    <React.Fragment>
      <Form
        form={props.form}

        style={{
          textAlign: "center",
          width: "40%",
          margin: "auto",
          marginTop: 32,
        }}
      >
        <Form.Item
          name="Payee"
          rules={[
            {
              required: true,
              message: "Payee is required.",
            },
          ]}
        >
          <Input placeholder="Payment Made Out To" />
        </Form.Item>
        <Form.Item
          name="MinCheckAmount"
          rules={[
            {
              required: true,
              message: "Minimum Payment Amount is required.",
            },
          ]}
        >
          <Input placeholder="Minimum Payment Amount" type="number" />
        </Form.Item>
        <Form.Item>
          <Captcha
            captchaRef={recaptchaRef}
            onLoadCallback={() => { }}
            onExpiredCallback={() => recaptchaRef.current?.reset()}
            verifyCallback={verifyCallback}
          />
        </Form.Item>
      </Form>
    </React.Fragment>
  );
});

export default Step4;
