import React from "react";
import isEqual from "react-fast-compare";
import AsyncRoute from "../../components/AsyncRoute";

import { Switch } from "react-router-dom";
import { findInTree } from "../../utils/collections/tree";
import { Menu } from "../../data";

let FILTERED_ROUTES: any[];

export function calculateRoutes(menu: Menu[]) {
  FILTERED_ROUTES = routes()
    .filter((i: Route) => findInTree(menu, i.path, "Path", "Children"))
    .map((i: Route, idx: number) => (
      <AsyncRoute exact key={i.path} path={i.path} imp={i.imp} />
    ));
  return Promise.resolve(FILTERED_ROUTES);
}

export const MainRouter = React.memo(
  (props: any) => {
    return (
      <Switch>
        {FILTERED_ROUTES}
        {commonRoutes()}
      </Switch>
    );
  },
  (p: any, n: any) => isEqual(p, n),
);

interface Route {
  path: string;
  imp: any;
}

function routes() {
  const routeArr: Route[] = [
    { path: "/", imp: () => import("../dashboard/DashboardRenderer") },
    { path: "/users", imp: () => import("../users/Users") },
    {
      path: "/buyerUsers",
      imp: () => import("../buyers/BuyerUsersPage"),
    },
    {
      path: "/publisherUsers",
      imp: () => import("../publishers/PublisherUsersPage"),
    },
    { path: "/roles", imp: () => import("../roles/Roles") },
    { path: "/menus", imp: () => import("../menus/Menus") },
    { path: "/system/api", imp: () => import("../system/Api") },
    { path: "/parameters", imp: () => import("../parameters/Parameters") },
    {
      path: "/emails/emailTemplates",
      imp: () => import("../emails/emailTemplates/EmailTemplates"),
    },
    {
      path: "/emails/outgoing",
      imp: () => import("../emails/EmailOutgoing"),
    },
    {
      path: "/activityLogs",
      imp: () => import("../activityLog/ActivityLog"),
    },
    {
      path: "/notifications",
      imp: () => import("../notification/Notification"),
    },
    {
      path: "/badWords",
      imp: () => import("../badWords/BadWords"),
    },
    {
      path: "/tags",
      imp: () => import("../tags/Tags"),
    },
    {
      path: "/regions",
      imp: () => import("../regions/Regions"),
    },
    {
      path: "/countries",
      imp: () => import("../countries/Countries"),
    },
    {
      path: "/categories",
      imp: () => import("../categories/Categories"),
    },
    {
      path: "/validations",
      imp: () => import("../validations/Validations"),
    },
    {
      path: "/fields",
      imp: () => import("../fields/Fields"),
    },
    {
      path: "/campaigns/list",
      imp: () => import("../campaign/CampaignList"),
    },
    {
      path: "/campaigns/new",
      imp: () => import("../campaign/detail/CampaignNew"),
    },
    {
      path: "/buyers/applications",
      imp: () => import("../applications/buyer/BuyersApplications"),
    },
    {
      path: "/applications",
      imp: () => import("../applications/Applications"),
    },
    {
      path: "/publisher/campaigns",
      imp: () => import("../publishers/campaign/PublisherCampaigns"),
    },
    {
      path: "/developerCampaigns",
      imp: () => import("../developers/campaign/Campaigns"),
    },
    {
      path: "/publisher/campaigns/subscribed",
      imp: () => import("../publishers/campaign/PublisherSubscribedCampaigns"),
    },
    {
      path: "/buyer/campaigns",
      imp: () => import("../buyers/list/BuyersCampaigns"),
    },
    {
      path: "/suppressions",
      imp: () => import("../suppressions/SuppressionLists"),
    },
    {
      path: "/monitor/ipgeo",
      imp: () => import("../monitor/ipgeo/IPGeoQueue"),
    },
    {
      path: "/monitor/zipCodes",
      imp: () => import("../monitor/zipCodes/ZipCodes"),
    },
    {
      path: "/monitor/outgoing",
      imp: () => import("../monitor/outgoing/Outgoing"),
    },
    {
      path: "/monitor/processor",
      imp: () => import("../monitor/processor/Processor"),
    },
    {
      path: "/monitor/redirector",
      imp: () => import("../monitor/affiliate/Redirector"),
    },
    {
      path: "/monitor/decider",
      imp: () => import("../monitor/affiliate/Decider"),
    },
    {
      path: "/monitor/npanxx",
      imp: () => import("../monitor/npanxx/NpaNxx"),
    },
    {
      path: "/clients/list",
      imp: () => import("../clients/Clients"),
    },
    {
      path: "/clients/insertionOrders",
      imp: () => import("../clients/insertionOrders/InsertionOrders"),
    },
    {
      path: "/leadOrders",
      imp: () => import("../leadOrders/LeadOrderList"),
    },
    {
      path: "/leads/rejected",
      imp: () => import("../leads/rejected/RejectedLeads"),
    },
    {
      path: "/leads/all",
      imp: () => import("../leads/all/LeadList"),
    },
    {
      path: "/leads/search",
      imp: () => import("../leads/search/SearchLeads"),
    },
    {
      path: "/leads/returns/buyer",
      imp: () => import("../leads/return/BuyerReturns"),
    },
    {
      path: "/leads/returns/publisher",
      imp: () => import("../leads/return/PublisherReturns"),
    },
    {
      path: "/leads/returns/upload",
      imp: () => import("../leads/return/Upload"),
    },
    {
      path: "/leads/unsold",
      imp: () => import("../leads/UnsoldLeads"),
    },
    {
      path: "/leads/flagged",
      imp: () => import("../leads/FlaggedLeads"),
    },
    {
      path: "/reports/campaign",
      imp: () => import("../report/campaign/CampaignReport"),
    },
    {
      path: "/reports/leadBuyer",
      imp: () => import("../report/buyer/BuyerReport"),
    },
    {
      path: "/reports/publisher",
      imp: () => import("../report/publisher/PublisherReport"),
    },
    {
      path: "/reports/category",
      imp: () => import("../report/CategoryReport"),
    },
    {
      path: "/dataEntry",
      imp: () => import("../dataentry/DataEntryForms"),
    },
    {
      path: "/publisher/reports/campaign",
      imp: () => import("../publishers/report/Campaign"),
    },
    {
      path: "/publisher/reports/returnedLead",
      imp: () => import("../publishers/report/ReturnedLeads"),
    },
    {
      path: "/reports/unsoldLeads",
      imp: () => import("../report/unsold/UnsoldLeadsReport"),
    },
    {
      path: "/reports/rejected",
      imp: () => import("../report/RejectedCampaignReport"),
    },
  ];

  return routeArr;
}

function commonRoutes() {
  return [
    <AsyncRoute
      exact
      key="/"
      path="/"
      imp={() => {
        return import("../dashboard/DashboardRenderer");
      }}
    />,
    <AsyncRoute
      exact
      key="/profile"
      path="/profile"
      imp={() => import("../profile/Profile")}
    />,
    <AsyncRoute
      exact
      key="/forbidden"
      path="/forbidden"
      imp={() => import("../error/Forbidden")}
    />,
    <AsyncRoute key="notfound" imp={() => import("../error/NotFound")} />,
  ];
}
