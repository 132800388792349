import React from "react";
import { Input, Form } from "antd";
import { PartnerProps } from "../../../data";

const Step3 = React.forwardRef((props: any, ref: any) => {
  return (
    <React.Fragment>
      <Form
        form={props.form}
        style={{
          textAlign: "center",
          width: "40%",
          margin: "auto",
          marginTop: 32,
        }}
      >
        <Form.Item
          name="Address"
          rules={[
            {
              required: true,
              message: "Address is required.",
            },
          ]}
        >
          <Input placeholder="Address" />
        </Form.Item>
        <Form.Item name="Address2">
          <Input placeholder="Address 2" />
        </Form.Item>
        <Form.Item
          name="City"
          rules={[
            {
              required: true,
              message: "City is required.",
            },
          ]}
        >
          <Input placeholder="City" />
        </Form.Item>
        <Form.Item
          name="State"
          rules={[
            {
              required: true,
              message: "State is required.",
            },
          ]}
        >
          <Input placeholder="State" />
        </Form.Item>
        <Form.Item name="Zip" rules={PartnerProps.Zip.rules}>
          <Input placeholder="Zip Code" type="number" />
        </Form.Item>
      </Form>
    </React.Fragment>
  );
});

export default Step3;
