import React from "react";
import moment, { Moment } from "moment";
import isEqual from "react-fast-compare";
import {DatePicker, DatePickerProps } from "antd";
import { defaultDatePickerFormat } from "../../../utils/formats/date";
/**
 * Creates a `DatePicker` as defined in {@link https://ant.design/components/date-picker/ AntD DatePicker}
 *
 * @param defaultValue - Default starting value for date picker
 *
 * @public
 */
const defaultProps: DatePickerProps = {
  format: defaultDatePickerFormat
}

const DateInput = (props: DatePickerProps) => {
  const { defaultValue, value, format, ...rest } = props;
  const dv = getValue(defaultValue, format);
  const v = getValue(value, format)

  return (
    <DatePicker
      defaultValue={dv}
      value={v}
      style={{ width: "100%" }}
      format={format}
      {...rest}
    />
  );
};
DateInput.defaultProps = defaultProps;

export default React.memo(DateInput, isEqual);

function getValue(value: Moment | null | undefined, format: any) {
  if (value && typeof value === "string") {
    const v = moment(value, String(format))
    return v.isValid() ? v : moment(value)
  }
  return value || undefined
}