import React from "react";
import { InputNumberProps } from "antd/lib/input-number";
import { InputNumber } from "antd";
import { css } from "@emotion/core";
import isEqual from "react-fast-compare";

export interface Props extends InputNumberProps {
  fractionSize?: number;
}

/**
 * Creates an `InputNumber` as defined in {@link https://ant.design/components/input-number/ AntD InputNumber} with fraction support
 *
 * @param fractionSize - Desired fraction size
 *
 * @public
 */

const NumberInput = (props: Props) => {
  const { prefix, fractionSize,formatter,parser } = props;
  return (
    <React.Fragment>
      {prefix && (
        <span css={prefixAddonCss} className="ant-input-group-addon">
          {prefix}
        </span>
      )}
      <InputNumber
        css={prefix && prefixInputCss}
        min={0}
        precision={fractionSize}
        {...props}
        onFocus={onFocus}
        formatter={formatter}
        parser={parser}
        
      />
    </React.Fragment>
  );
};

export default React.memo(NumberInput, isEqual);

const onFocus = (e: any) => {
  const index = e.currentTarget.value.length;
  e.currentTarget.setSelectionRange(index, index);
};

const prefixAddonCss = css`
  height: 32px;
  padding-bottom: 3px;
  padding-top: 0px;
`;

const prefixInputCss = css`
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
`;
