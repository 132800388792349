import { load, IReq, QApi, qapi2Url } from "./index";
import { BadWord } from "../models/BadWord";

export const list = (req?: any, api?: QApi): Promise<BadWord[]> =>
  load(`badWords${qapi2Url(api)}`, "GET", false, undefined, api);
export const create = (data: BadWord): Promise<BadWord> =>
  load(`badWords`, "POST", true, data);
export const read = (req: IReq): Promise<BadWord> =>
  load(`badWords/${req.ID}`, "GET", true, undefined);
export const update = (req: IReq, data: BadWord): Promise<BadWord> =>
  load(`badWords/${req.ID}`, "PUT", true, data);
export const del = (req: IReq): Promise<BadWord> =>
  load(`badWords/${req.ID}`, "DELETE", true, undefined);
