import { load, IReq, QApi, qapi2Url } from "./index";
import { SuppressionList } from "../models/SuppressionList";

export const list = (campaignID: number | string, req?: any, api?: QApi): Promise<SuppressionList[]> =>
  load(`campaigns/${campaignID}/suppressionLists${qapi2Url(api)}`, "GET", false, undefined, api);
export const create = (campaignID: number | string, data: SuppressionList): Promise<SuppressionList> =>
  load(`campaigns/${campaignID}/suppressionLists`, "POST", true, data);
export const read = (campaignID: number | string, req: IReq): Promise<SuppressionList> =>
  load(`campaigns/${campaignID}/suppressionLists/${req.ID}`, "GET", true, undefined);
export const update = (campaignID: number | string, req: IReq, data: SuppressionList): Promise<SuppressionList> =>
  load(`campaigns/${campaignID}/suppressionLists/${req.ID}`, "PUT", true, data);
export const del = (campaignID: number | string, req: IReq): Promise<SuppressionList> =>
  load(`campaigns/${campaignID}/suppressionLists/${req.ID}`, "DELETE", true, undefined);


export const assign = (campaignID: number | string, data: SuppressionList[]): Promise<SuppressionList> =>
  load(`campaigns/${campaignID}/suppressionLists/assign`, "POST", true, data);