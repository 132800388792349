import { DataSource } from ".";
import { list, create, read, update, del } from "../api/regions";
import { Region } from "../models/Region";
export const RegionsDS: DataSource<Region> = {
  list,
  create,
  read,
  update,
  del,
};

export default RegionsDS;
