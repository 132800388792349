const required = {
  required: true,
  message: "The field is required.",
};

const len = (val: number) => ({
  len: val,
  message: `Must be ${val} characters.`,
});
const min = (val: number) => ({
  min: val,
  transform: transformToString,
  message: `Must be at least ${val} characters long.`,
});
const max = (val: number, type: string = "string") => ({
  max: val,
  transform: transformToString,
  message: `Must be at up ${val} characters long. ${type}`,
});
const minMax = (minVal: number, maxVal: number) => ({
  min: minVal,
  max: maxVal,
  transform: transformToString,
  message: `Must be ${minVal} to ${maxVal} characters long.`,
});
const type = (val: string) => ({
  type: val,
});
const pattern = (val: string, givenMessage: string) => ({
  pattern: new RegExp(val),
  message: givenMessage,
});


function transformToString(a: any) {
  return a + "";
}

const def = {
  required,
  len,
  min,
  max,
  minMax,
  type,
  pattern,
};

export default def;