import { DataSource } from ".";
import { categoryCampaignReport as list } from "../api/report";
import { CampaignReport } from "../models/CampaignReport";



export const CategoryCampaignReportDS = (categoryID: string | number):  DataSource<CampaignReport>  => ({
  list: list.bind(undefined,categoryID),
  create: () => { throw "Not implemented"; }, //eslint-disable-line
  update: () => { throw "Not implemented"; }, //eslint-disable-line
  del: () => { throw "Not implemented"; }, //eslint-disable-line
  read: () => { throw "Not implemented"; }, //eslint-disable-line
});