import { load, IReq, QApi, qapi2Url } from "./index";
import { Publisher } from "../models/Publisher";
import { ActivityLog } from "../models/ActivityLog";
import { User } from "../models/User";

export const list = (req?: any, api?: QApi): Promise<Publisher[]> =>
  load(`publishers${qapi2Url(api)}`, "GET", true, undefined, api);

export const create = (data: Publisher): Promise<Publisher> =>
  load(`publishers`, "POST", true, data);
export const read = (req: IReq): Promise<Publisher> =>
  load(`publishers/${req.ID}`, "GET", true, undefined);
export const update = (req: IReq, data: Publisher): Promise<Publisher> =>
  load(`publishers/${req.ID}`, "PUT", true, data);
export const del = (req: IReq): Promise<Publisher> =>
  load(`publishers/${req.ID}`, "DELETE", true, undefined);
export const accept = (req: IReq): Promise<Publisher> =>
  load(`publishers/${req.ID}/approve`, "PUT", true, undefined);
export const decline = (req: IReq): Promise<Publisher> =>
  load(`publishers/${req.ID}/decline`, "PUT", true, undefined);
export const updatePartial = (req: IReq, data: Partial<Publisher>): Promise<any> =>
  load(`publishers/${req.ID}`, "PATCH", true, data);

export const getActivities = (publisherID: number | string, req?: IReq, api?: QApi): Promise<ActivityLog[]> =>
  load(`publishers/${publisherID}/activity${qapi2Url(api)}`, "GET", true, undefined);
export const getUsers = (publisherID: number | string, req?: IReq, api?: QApi): Promise<User[]> =>
  load(`publishers/${publisherID}/user${qapi2Url(api)}`, "GET", true, undefined);

export const createPubUser = (publisherID: number | string, data: User): Promise<User> =>
  load(`publishers/${publisherID}/user`, "POST", true, data);
export const updatePubUser = (publisherID: number | string, req: IReq, data: User): Promise<User> =>
  load(`publishers/${publisherID}/user/${req.ID}`, "PUT", true, data);

export const registerPublisher = (
  data: Publisher,
  responseKey: string,
): Promise<Publisher> =>
  load(`public/register/publisher/${responseKey}`, "POST", false, data);
