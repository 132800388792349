import { User } from "../models/User";
import { getUsers as list, createBuyerUser, updateBuyerUser } from "../api/buyers";
import { DataSource } from ".";

export const BuyerUserDS = (buyerID: string | number): DataSource<User> => ({
  list: list.bind(undefined, buyerID),
  create: createBuyerUser.bind(undefined, buyerID), 
  update: updateBuyerUser.bind(undefined, buyerID),
  del: () => { throw "Not implemented"; }, //eslint-disable-line
  read: () => { throw "Not implemented"; }, //eslint-disable-line
});

export default BuyerUserDS;