import { Moment } from "moment";
import validation from "../../utils/messages/validation";
import BaseModel from "./BaseModel";
import { LandingPage } from "./LandingPage";
import { LeadOrderFilter } from "./LeadOrderFilter";
import { MappingField } from "./MappingField";
import { LeadOrderStatus } from "./LeadOrderStatus";
import { Rate } from "./Rate";
import { DeliverySetting } from "./DeliverySetting";
import { PublisherDeal } from "./PublisherDeal";
import { Timezone } from "./Timezone";

export interface LeadOrder extends BaseModel {
  Name: string;
  BuyerID: number;
  CategoryID: number;
  Active: boolean;
  Price: number;
  D1Cap: number;
  D2Cap: number;
  D3Cap: number;
  D4Cap: number;
  D5Cap: number;
  D6Cap: number;
  D7Cap: number; // Sunday
  WeeklyCap: number;
  TotalCap: number;
  Exclusive: boolean; //Means no duplicate leads
  IsActive: boolean;
  Timezone: Timezone;
  StartDate: Moment;
  EndDate: Moment;
  WeekdayStartTime: string;
  WeekdayEndTime: string;
  WeekendStartTime: string;
  WeekendEndTime: string;
  Status: LeadOrderStatus;
  LeadOrderFilters: LeadOrderFilter[];
  BlockedLandingPages: LandingPage[];
  MappingFields: MappingField[];
  Rates: Rate[];
  BlockedWebTrafficPublisherDeals: PublisherDeal[];
  BlockedDirectPostPublisherDeals: PublisherDeal[];
  TotalCapReached: boolean;
  DeliverySetting?: DeliverySetting;
}
export const LeadOrderProps = {
  Name: { rules: [validation.required, validation.min(4), validation.max(1024)] },
  CategoryID: { rules: [validation.required] },
  BuyerID: { rules: [validation.required] },
  Price: { rules: [validation.required] },
  Status: { rules: [validation.required] },
  StartTime: { rules: [validation.required] },
};
