import validation from "../../utils/messages/validation";

export interface Country {
  ID: number;
  Code: string;
  Name: string;
  PhoneCode: string;
}
export const CountryProps = {
  Code: {
    rules: [validation.required],
  },
  Name: {
    rules: [validation.required, validation.minMax(3, 50)],
  },
};
