import React from "react";

import { rowCss } from "../../components/publicPage/PublicPage.styles";
import { css } from "@emotion/core";
import HalfRed from "../../components/background/HalfRed";

interface Props {
  age: number;
  name: string;
  history: any;
  location: any;
  match: any;
}

export const Verify = (props: Props) => {
  return (
    <HalfRed>
      <div css={rowCss}>
        <div css={descCss}>
          Your membership request has been created successfully. After
          approval, your membership will be activated and you will be
          informed.
        </div>
      </div>
    </HalfRed>
  );
};

export const descCss = css`
  text-align: center;
  margin-left: -14px;
  font-size: 22px;
  font-weight: bold;
  color: white;
`;
export default Verify;
