import { DataSource } from ".";
import { list, create, read, update, del } from "../api/dataEntry";
import { DataEntry } from "../models/DataEntry";
export const DataEntryDS: DataSource<DataEntry> = {
  list,
  create,
  read,
  update,
  del,
};

export default DataEntryDS;
