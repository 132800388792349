import validation from "../../utils/messages/validation";
import { Field } from "./Field";

export interface DataEntryField {
  ID: number;
  FieldID: number;
  DataEntryID: number;
  Type: number;
  Options: string;
  SortOrder: number;
  Label: string;
  Default: string;
  Field: Field;
}

export const DataEntryFieldProps = {
  FieldID: {
    rules: [validation.required],
  },
  DataEntryID: {
    rules: [validation.required],
  },
  Type: {
    rules: [validation.required],
  },
  Label: {
    rules: [validation.required],
  },
};

export enum DataEntryFieldType {
  TextBox = 1,
  TextArea,
  RadioButton,
  Checkbox,
  DropdownList,
  MultiSelect,
  Date,
  DateTime
}

export const DataEntryFieldTypeArray = [
  { ID: DataEntryFieldType.TextBox, Name: "TextBox" },
  { ID: DataEntryFieldType.TextArea, Name: "TextArea" },
  { ID: DataEntryFieldType.RadioButton, Name: "Radio Button" },
  { ID: DataEntryFieldType.Checkbox, Name: "Checkbox" },
  { ID: DataEntryFieldType.DropdownList, Name: "Dropdown List" },
  { ID: DataEntryFieldType.MultiSelect, Name: "Multi Select" },
  { ID: DataEntryFieldType.Date, Name: "Date" },
  { ID: DataEntryFieldType.DateTime, Name: "DateTime" },
];