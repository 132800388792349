import { DataSource } from "./index";
import { getReturns } from "../api/leads";
import { Lead } from "..";
export const ReturnLeadDS = (partner: "buyer" | "publisher" | "upload"): DataSource<Lead> => ({
  list: getReturns.bind(undefined, partner),
  create: () => { throw "Not implemented"; }, //eslint-disable-line
  read: () => { throw "Not implemented"; }, //eslint-disable-line
  update: () => { throw "Not implemented"; }, //eslint-disable-line
  del: () => { throw "Not implemented"; }, //eslint-disable-line
});

export default ReturnLeadDS;


