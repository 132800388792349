import { load, IReq, QApi, qapi2Url } from "./index";
import { Country } from "../models/Country";

export const list = (req?: any, api?: QApi): Promise<Country[]> =>
  load(`countries${qapi2Url(api)}`, "GET", false, undefined, api);
export const create = (data: Country): Promise<Country> =>
  load(`countries`, "POST", true, data);
export const read = (req: IReq): Promise<Country> =>
  load(`countries/${req.ID}`, "GET", true, undefined);
export const update = (req: IReq, data: Country): Promise<Country> =>
  load(`countries/${req.ID}`, "PUT", true, data);
export const del = (req: IReq): Promise<Country> =>
  load(`countries/${req.ID}`, "DELETE", true, undefined);
