import { DataSource } from ".";
import { buyerCategoryReport as list } from "../api/report";
import { BuyerReport } from "../models/BuyerReport";

export const BuyerCategoryReportDS = (buyerID: string | number):  DataSource<BuyerReport>  => ({
  list: list.bind(undefined,buyerID),
  create: () => { throw "Not implemented"; }, //eslint-disable-line
  update: () => { throw "Not implemented"; }, //eslint-disable-line
  del: () => { throw "Not implemented"; }, //eslint-disable-line
  read: () => { throw "Not implemented"; }, //eslint-disable-line
});