import React from "react";
import css from "@emotion/css";
import { splitPermitations } from "../../../utils/collections/strings";
import { findInTree } from "../../../utils/collections/tree";
import { Menu } from "../../../data";

interface Props {
  path: string;
  menu: Menu[];
}

export const Breadcrumb = React.memo((props: Props) => (
  <span css={textCss}>{parseBreadcrumb(props.path, props.menu)}</span>
));

function parseBreadcrumb(path: string, menu: Menu[]): string {
  const br: string[] = [];
  for (const key of splitPermitations(path)) {
    const selectedMenu = findInTree(menu, `/${key}`, "Path");
    if (selectedMenu) {
      br.push(selectedMenu.Name);
    }
  }
  return br.reverse().join(" / ");
}

const textCss = css`
  font-weight: bold;
  font-size: 18px;
`;
