import { load, IReq, QApi, qapi2Url } from "./index";
import { Buyer } from "../models/Buyer";
import { ActivityLog } from "../models/ActivityLog";
import { User } from "../models/User";

export const list = (req?: any, api?: QApi): Promise<Buyer[]> =>
  load(`buyers${qapi2Url(api)}`, "GET", false, undefined, api);
export const create = (data: Buyer): Promise<Buyer> =>
  load(`buyers`, "POST", true, data);
export const read = (req: IReq): Promise<Buyer> =>
  load(`buyers/${req.ID}`, "GET", true, undefined);
export const update = (req: IReq, data: Buyer): Promise<Buyer> =>
  load(`buyers/${req.ID}`, "PUT", true, data);
export const del = (req: IReq): Promise<Buyer> =>
  load(`buyers/${req.ID}`, "DELETE", true, undefined);
export const accept = (req: IReq): Promise<Buyer> =>
  load(`buyers/${req.ID}/approve`, "PUT", true, undefined);
export const decline = (req: IReq): Promise<Buyer> =>
  load(`buyers/${req.ID}/decline`, "PUT", true, undefined);

  export const getActivities = (buyerID: number | string, req?: IReq, api?: QApi): Promise<ActivityLog[]> =>
  load(`buyers/${buyerID}/activity${qapi2Url(api)}`, "GET", true, undefined);
export const getUsers = (buyerID: number | string, req?: IReq, api?: QApi): Promise<User[]> =>
  load(`buyers/${buyerID}/user${qapi2Url(api)}`, "GET", true, undefined);

export const createBuyerUser = (buyerID: number | string, data: User): Promise<User> =>
  load(`buyers/${buyerID}/user`, "POST", true, data);
export const updateBuyerUser = (buyerID: number | string, req: IReq, data: User): Promise<User> =>
  load(`buyers/${buyerID}/user/${req.ID}`, "PUT", true, data);

export const registerBuyer = (
  data: Buyer,
  responseKey: string,
): Promise<Buyer> =>
  load(`public/register/buyer/${responseKey}`, "POST", false, data);
