import validation from "../../utils/messages/validation";
import { Buyer } from "./Buyer";
import { Campaign } from "./Campaign";
import { LeadOrder } from "./LeadOrder";
import { Publisher } from "./Publisher";

export interface InsertionOrder {
  ID: number;
  PartnerType: PartnerType;
  BuyerID: number;
  PublisherID: number;
  CampaignID: number;
  LeadOrderID: number;
  Buyer: Buyer;
  Publisher: Publisher;
  Campaign: Campaign;
  LeadOrder: LeadOrder;
  StartTime: string;
  EndTime: string;
  JobName: string;
  Terms: string;
  PricePerLead: number;
  TotalAmount: string;
  Notes: string;
  Warranties: string;
  AdminNotes: string;
  UniqueID: string;
}

export enum PartnerType {
  Publisher = 0,
  Buyer,
}

export const InsertionOrderProps = {
  PartnerType: {
    rules: [validation.required],
  },
  CampaignID: {
    rules: [validation.required],
  },
  StartTime: {
    rules: [validation.required],
  },
  EndTime: {
    rules: [validation.required],
  },
  JobName: {
    rules: [validation.required],
  },
  Terms: {
    rules: [validation.required],
  },
  PricePerLead: {
    rules: [validation.required],
  },
  TotalAmount: {
    rules: [validation.required],
  },
  Notes: {
    rules: [validation.required],
  },
  Warranties: {
    rules: [validation.required],
  },
  AdminNotes: {
    rules: [validation.required],
  },
};
