import { DataSource } from "./index";
import { list, create, read, update, del } from "../api/users";
import { User } from "../models/User";
export const UsersDS: DataSource<User> = {
  list,
  create,
  read,
  update,
  del,
};

export default UsersDS;
