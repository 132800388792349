import { load, IReq, QApi, qapi2Url } from "./index";
import { Field } from "../models/Field";

export const list = (req?: any, api?: QApi): Promise<Field[]> =>
  load(`fields${qapi2Url(api)}`, "GET", false, undefined, api);
export const create = (data: Field): Promise<Field> =>
  load(`fields`, "POST", true, data);
export const read = (req: IReq): Promise<Field> =>
  load(`fields/${req.ID}`, "GET", true, undefined);
export const update = (req: IReq, data: Field): Promise<Field> =>
  load(`fields/${req.ID}`, "PUT", true, data);
export const del = (req: IReq): Promise<Field> =>
  load(`fields/${req.ID}`, "DELETE", true, undefined);
