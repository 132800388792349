import { User } from "../models/User";
import { getUsers as list, createPubUser, updatePubUser } from "../api/publishers";
import { DataSource } from ".";

export const PublisherUserDS = (publisherID: string | number): DataSource<User> => ({
  list: list.bind(undefined, publisherID),
  create: createPubUser.bind(undefined, publisherID), 
  update: updatePubUser.bind(undefined, publisherID),
  del: () => { throw "Not implemented"; }, //eslint-disable-line
  read: () => { throw "Not implemented"; }, //eslint-disable-line
});

export default PublisherUserDS;