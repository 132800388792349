import React from "react";
import HalfRed from "../../../components/background/HalfRed";
import { logoCss } from "../../../styles";
import { Button, Alert, Steps, message ,Form } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { css } from "@emotion/core";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { registerBuyer } from "../../../data/api/buyers";
import { Buyer } from "../../../data";
import { sha256 } from "js-sha256";

export default function BuyerRegister() {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [recaptchaValid, setRecaptchaValid] = React.useState(false);
  const [recaptchaLoaded, setRecaptchaLoaded] = React.useState(false);
  const [recaptchaResponseKey, setRecaptchaResponseKey] = React.useState(
    "",
  );
  const [errmessage, setErrMessage] = React.useState<string | undefined>(
    undefined,
  );
  const [current, setCurrent] = React.useState(0);
  const [formData, setFormData] = React.useState<Buyer>({} as Buyer);

  const onClick = React.useCallback(
    (incomingCurrent: number) => {
      if (incomingCurrent > current) {
        form
          ?.validateFields()
          .then((resp: any) => {
            const data = Object.assign({...formData}, resp);
            if (form.getFieldValue("Email")) {
              data.ContactEmail = form.getFieldValue(
                "Email",
              );
            }
            setFormData(data);
            setCurrent(incomingCurrent);
            setError(false);
            setErrMessage(undefined);
          })
          .catch((err: any) => {
            setError(true);
            setErrMessage("Please enter required fields.");
          });
      } else {
        setCurrent(incomingCurrent);
      }
    },
    [ formData,form, current],
  );

  const next = React.useCallback(() => {
    form
      ?.validateFields()
      .then((resp: any) => {
        const data = Object.assign({...formData}, resp);
        if (form?.getFieldValue("Email")) {
          data.ContactEmail = form?.getFieldValue("Email");
        }
        setFormData(data);
        setCurrent(current + 1);
        setError(false);
        setErrMessage(undefined);
      })
      .catch((err: any) => {
        setError(true);
        setErrMessage("Please enter required fields.");
      });
  }, [  formData,current, form]);

  const prev = React.useCallback(() => {
    setCurrent(current - 1);
  }, [current]);

  React.useLayoutEffect(() => {
    form.setFieldsValue(formData);
  }, [formData, form]);

  const onSubmit = React.useCallback(
    (values: any) => {
      if (recaptchaLoaded && recaptchaValid && recaptchaResponseKey) {
        setLoading(true);
        form
          ?.validateFields()
          .then((resp: any) => {
            const data = Object.assign({...formData}, resp);
            setFormData(data);
            setError(false);
            setErrMessage(undefined);
            setLoading(false);
          })
          .then(() => {
            formData.Password = sha256(formData.Password);
            formData.PasswordAgain = sha256(formData.PasswordAgain);
            registerBuyer(formData, recaptchaResponseKey)
              .then(() => {
                window.location.hash = "/verify";
              })
              .catch((err) => {
                if (err.status === 409) {
                  message.error("An user with this e-mail already exist.");
                } else {
                  message.error("An error occurred.");
                }
              });
          })
          .catch((err: any) => {
            setError(true);
            setErrMessage("Please enter required fields.");
            setLoading(false);
          });
      } else {
        setError(true);
        setErrMessage("You need to confirm captcha.");
      }
    },
    [
      formData,
      form,
      recaptchaLoaded,
      recaptchaResponseKey,
      recaptchaValid,
    ],
  );

  const setSubmitable = React.useCallback(
    (
      recaptchaLoaded: boolean,
      recaptchaValid: boolean,
      recaptchaResponseKey: string,
    ) => {
      setRecaptchaLoaded(recaptchaLoaded);
      setRecaptchaValid(recaptchaValid);
      setRecaptchaResponseKey(recaptchaResponseKey);
    },
    [],
  );

  const steps = [
    {
      title: "Member",
      content: <Step1 form={form} />,
    },
    {
      title: "Company",
      content: <Step2 form={form} />,
    },
    {
      title: "Address",
      content: <Step3 form={form} setSubmitable={setSubmitable} />,
    }
  ];
  return (
    <HalfRed>
      <img css={logoCss} src="logo1.png" alt="logo" />
      <div css={containerCss}>
        <div css={titleCss}>Become a Lead Buyer</div>
        <Steps
          current={current}
          type="navigation"
          size="small"
          onChange={onClick}
        >
          {steps.map((item) => (
            <Steps.Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{steps[current].content}</div>
        {errmessage && (
          <Alert
            message={errmessage}
            type={error ? "error" : "success"}
            showIcon={!error}
          />
        )}
        <div className="steps-action" style={{ marginTop: 16 }}>
          {current > 0 && <Button onClick={() => prev()}>Previous</Button>}
          {current === steps.length - 1 && (
            <Button
              htmlType="submit"
              loading={loading}
              onClick={onSubmit}
              style={{
                letterSpacing: 1,
                backgroundColor: "#e31d23",
                borderColor: "#c71b20",
                fontWeight: "bold",
                float: "right",
              }}
              type="primary"
              icon={<CheckOutlined />}
            >
              Register
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button
              type="primary"
              style={{ float: "right" }}
              onClick={() => next()}
            >
              Next
            </Button>
          )}
        </div>
      </div>
    </HalfRed>
  );
}

export const titleCss = css`
  width: 80%;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bolder;
  /* color:white; */
  line-height: 1.3;
`;
export const containerCss = css`
  width: 90%;
  background-color: whitesmoke;
  padding: 20px;
  box-shadow: 1px 1px 10px black;
  overflow: auto;
`;
