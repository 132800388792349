import { DataSource } from ".";
import { list } from "../api/npanxx";
import { NpaNxx } from "../models/NpaNxx";
export const NpaNxxDS: DataSource<NpaNxx> = {
  list,
  create: () => { throw "Not implemented"; }, //eslint-disable-line
  read: () => { throw "Not implemented"; }, //eslint-disable-line
  update: () => { throw "Not implemented"; }, //eslint-disable-line
  del: () => { throw "Not implemented"; }, //eslint-disable-line
};

export default NpaNxxDS;
