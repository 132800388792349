import React from "react";
import css from "@emotion/css";

import { Tooltip, Button } from "antd";

import { ButtonType, ButtonSize } from "antd/lib/button";

import { Link } from "react-router-dom";

interface Props {
  tooltip?: string;
  type: ButtonType;
  to?: string | any;
  icon: React.ReactNode;
  onClick?: any;
  size?: ButtonSize;
  loading?: boolean;
  name?: string;
}

export const HeaderButton = React.memo(
  (props: Props): JSX.Element => {
    const button = (
      <Button
        type={props.type}
        icon={props.icon}
        onClick={props.onClick}
        size={props.size}
        loading={props.loading}
        name={props.name}
      />
    );
    const link = props.to ? (
      <Link to={props.to} css={linkCss}>
        {button}
      </Link>
    ) : (
      <div css={linkCss}>{button}</div>
    );
    return props.tooltip ? (
      <Tooltip title={props.tooltip}>{link}</Tooltip>
    ) : (
      link
    );
  },
);

const linkCss = css`
  margin-left: 10px;
  float: right;
`;
