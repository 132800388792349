import { DataSource } from ".";
import { list, create, read, update, del } from "../api/campaignCreatives";
import { Creative } from "../models/Creative";
export const CampaignCreativesDS = (campaignID: string | number): DataSource<Creative> => ({
  list: list.bind(undefined, campaignID),
  create: create.bind(undefined, campaignID),
  read: read.bind(undefined, campaignID),
  update: update.bind(undefined, campaignID),
  del: del.bind(undefined, campaignID),
});

export default CampaignCreativesDS;

