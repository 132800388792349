import React from "react";
import {
  TeamOutlined,
  EyeOutlined,
  ToolOutlined,
  SolutionOutlined,
  ProjectOutlined,
  MailOutlined,
  AuditOutlined,
  SearchOutlined,
  CloseCircleOutlined,
  CommentOutlined,
  FilePdfOutlined,
  FileAddOutlined,
  ClusterOutlined,
  LineChartOutlined,
  FileUnknownOutlined,
  FieldTimeOutlined,
  TagOutlined,
  GlobalOutlined,
  EnvironmentOutlined,
  GroupOutlined,
  CheckCircleOutlined,
  PartitionOutlined,
  PlusSquareOutlined,
  UnorderedListOutlined,
  ExceptionOutlined,
  MonitorOutlined,
  AppstoreAddOutlined,
  UsergroupAddOutlined,
  ShoppingOutlined,
  HourglassOutlined,
  FileTextOutlined,
  FormOutlined,
  NotificationOutlined
} from "@ant-design/icons";

export function getMenuIcon(icon: string): JSX.Element | undefined {
  switch (icon) {
    case "AuditOutlined":
      return <AuditOutlined/>;
    case "SearchOutlined":
      return <SearchOutlined/>;
    case "TeamOutlined":
      return <TeamOutlined/>;
    case "EyeOutlined":
      return <EyeOutlined/>;
    case "ToolOutlined":
      return <ToolOutlined/>;
    case "SolutionOutlined":
      return <SolutionOutlined/>;
    case "ProjectOutlined":
      return <ProjectOutlined/>;
    case "MailOutlined":
      return <MailOutlined/>;
    case "CloseCircleOutlined":
      return <CloseCircleOutlined/>;
    case "CommentOutlined":
      return <CommentOutlined/>;
    case "FilePdfOutlined":
      return <FilePdfOutlined/>;
    case "FileAddOutlined":
      return <FileAddOutlined/>;
    case "ClusterOutlined":
      return <ClusterOutlined/>;
    case "LineChartOutlined":
      return <LineChartOutlined/>;
    case "FileUnknownOutlined":
      return <FileUnknownOutlined/>;
    case "FieldTimeOutlined":
      return <FieldTimeOutlined/>;
    case "TagOutlined":
      return <TagOutlined/>;
    case "GlobalOutlined":
      return <GlobalOutlined/>;
    case "EnvironmentOutlined":
      return <EnvironmentOutlined/>;
    case "GroupOutlined":
      return <GroupOutlined/>;
    case "CheckCircleOutlined":
      return <CheckCircleOutlined/>;
    case "PartitionOutlined":
      return <PartitionOutlined/>;
    case "PlusSquareOutlined":
      return <PlusSquareOutlined/>;
    case "UnorderedListOutlined":
      return <UnorderedListOutlined/>;
    case "ExceptionOutlined":
      return <ExceptionOutlined/>;
    case "MonitorOutlined":
      return <MonitorOutlined/>;
    case "AppstoreAddOutlined":
      return <AppstoreAddOutlined/>;
    case "UsergroupAddOutlined":
      return <UsergroupAddOutlined/>;
    case "ShoppingOutlined":
      return <ShoppingOutlined/>;
    case "HourglassOutlined":
      return <HourglassOutlined/>;
    case "FileTextOutlined":
      return <FileTextOutlined/>;
    case "FormOutlined":
      return <FormOutlined/>;
    case "NotificationOutlined":
      return <NotificationOutlined/>;

    default:
      return undefined;
  }
}
