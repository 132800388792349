import { DataSource } from ".";
import { list, create, read, update, del } from "../api/campaignValidations";
import { Validation } from "../models/Validation";
export const CampaignValidationsDS = (campaignID: string | number): DataSource<Validation> => ({
  list:list.bind(undefined,campaignID),
  create:create.bind(undefined,campaignID),
  read:read.bind(undefined,campaignID),
  update:update.bind(undefined,campaignID),
  del:del.bind(undefined,campaignID),
});

export default CampaignValidationsDS;

