import { DataSource } from "./index";
import { list, create, read, update, del } from "../api/menus";
import { Menu } from "../models/Menu";
export const MenusDS: DataSource<Menu> = {
  list,
  create,
  read,
  update,
  del,
};

export default MenusDS;
