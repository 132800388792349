import { DataSource } from ".";
import { list, read } from "../api/emails";
import { Email } from "../models/Email";
export const EmailsDS: DataSource<Email> = {
  list,
  read,
  create: () => { throw "Not implemented"; }, //eslint-disable-line
  update: () => { throw "Not implemented"; }, //eslint-disable-line
  del: () => { throw "Not implemented"; }, //eslint-disable-line
};

export default EmailsDS;
