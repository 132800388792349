import { load, IReq, QApi, qapi2Url } from "./index";
import { Role } from "../models/Role";

export const list = (req?: any, api?: QApi): Promise<Role[]> =>
  load(`roles${qapi2Url(api)}`, "GET", false, undefined, api);
export const create = (data: Role): Promise<Role> =>
  load(`roles`, "POST", true, data);
export const read = (req: IReq): Promise<Role> =>
  load(`roles/${req.ID}`, "GET", true, undefined);
export const update = (req: IReq, data: Role): Promise<Role> =>
  load(`roles/${req.ID}`, "PUT", true, data);
export const del = (req: IReq): Promise<Role> =>
  load(`roles/${req.ID}`, "DELETE", true, undefined);
