import { DataSource } from ".";
import { list, listDeleted, create, read, update, del, campaignStatus } from "../api/campaigns";
import { Campaign } from "../models/Campaign";
const CampaignsDS: DataSource<Campaign> = {
  list,
  create,
  read,
  update,
  del,
};

const CampaignDeletedDS: DataSource<Campaign> = {
  list: listDeleted.bind(undefined),
  create,
  read,
  update,
  del,

};

const CampaignStatusDS: any = {
  campaignStatus
};

export {
  CampaignsDS,
  CampaignDeletedDS,
  CampaignStatusDS
}