import { load, IReq } from "./index";
import { Parameter } from "../models/Parameter";

export const list = (req?: any): Promise<Parameter[]> =>
  load(`parameters`, "GET", false, undefined);
export const create = (data: Parameter): Promise<Parameter> =>
  load(`parameters`, "POST", true, data);
export const read = (req: IReq): Promise<Parameter> =>
  load(`parameters/${req.ID}`, "GET", true, undefined);
export const update = (req: IReq, data: Parameter): Promise<Parameter> =>
  load(`parameters/${req.ID}`, "PUT", true, data);
export const del = (req: IReq): Promise<Parameter> =>
  load(`parameters/${req.ID}`, "DELETE", true, undefined);
