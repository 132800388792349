import React from "react";
export const LanguageContext = React.createContext({
  yes: "Yes",
  no: "No",
  cancel: "Cancel",
  add: "Add",
  addChild: "Add Child",
  edit: "Edit",
  search: "Search",
  detailedSearch:"Detailed Search",
  clear: "Clear",
  totalRecords: "Total record",
  deleteConfirm: "Do you really want to delete this record?",
  genericError: "An error occurred.",
  dataLoadError:"Data loading error.",
  createSucceeded:"Record created successfully",
  updateSucceeded:"Record updated successfully",
  deleteSucceeded:"Record deleted successfully",
});
