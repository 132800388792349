// TODO:need to add flatkey
import React from "react";
import { Table, Input } from "antd";
import { TableProps } from "antd/lib/table";
import { ModelProps } from "../AutoForm";
import { LanguageContext } from "../../../contexts/LanguageContext";
import isEqual from "react-fast-compare";

interface Props<T> {
  columns: Array<ModelProps<any>>;
  table?: TableProps<T>;
  options?: T[];
  optionsAsync?: () => T[];
  value?: any;
  onChange?: (checked: any[]) => void;
  disabled?: boolean;
  dropdownFilter: boolean;
}

interface State<T> {
  selectedRowKeys: any[];
  searchText: string;
  data: T[];
}

class CheckTable<T> extends React.Component<Props<T>, State<T>> {
  public static contextType = LanguageContext;
  public static defaultProps: Partial<Props<any>> = {
    dropdownFilter: true
  };

  public state: State<T> = { selectedRowKeys: this.props.value?.map((item: any) => item.ID), searchText: "", data: this.getOptions(this.props) };
  private prevData: T[] = [];
  private searchInput: any;
  private filter: any = {

    onFilter: (value: any, record: any) =>
      Object.keys(record)
        .map((key) => record[key])
        .join(";")
        .toLocaleLowerCase()
        .indexOf(value.toLocaleLowerCase()) > -1,
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus();
        });
      }
    },
  };
  constructor(props: Props<T>) {
    super(props);
    if (this.props.dropdownFilter) {
      this.filter.filterDropdown = (filterprops: any) => {
        const { setSelectedKeys, selectedKeys, confirm } = filterprops;
        return (
          <div>
            <Input
              ref={(ele) => (this.searchInput = ele)}
              placeholder={this.context["search"]}
              style={{ width: 100 }}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={(e) => {
                e.preventDefault();
                this.handleSearch(selectedKeys, confirm);
              }}
            />
          </div>
        );
      };

    }

  }
  public shouldComponentUpdate(nextProps: Props<T>, nextState: any) {
    const { value } = this.props;
    const { value: nValue } = nextProps;
    const nOptions = this.getOptions(nextProps);
    let isOptionsEqual = true;
    if (!isEqual(this.prevData, nOptions)) {
      this.prevData = nOptions;
      isOptionsEqual = false;
    }
    if (!isEqual(this.state, nextState)) {
      return true;
    }
    if (!isEqual(this.props.table, nextProps.table)) {
      return true;
    }
    return !isEqual(value, nValue) || !isOptionsEqual;
    
  }

  public componentDidUpdate(prevProps: any) {
    if (prevProps.options !== this.props.options) {
      this.setState({ data: this.getOptions(this.props) });
    }
    if (this.props.value && prevProps.value !== this.props.value) {
      this.setState({ selectedRowKeys:this.props.value.map((item: any) => item.ID) });
    }

  }
 
  public render() {
    const { columns, dropdownFilter } = this.props;
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onCheck,
      getCheckboxProps: (record: any) => ({
        disabled: this.props.disabled,
      }),
    };
    const cols = columns.filter((item) => item.visibleColumn !== false);
    cols[cols.length - 1] = { ...this.filter, ...cols[cols.length - 1] };
    const table = <Table
      rowKey="ID"
      size="small"
      pagination={false}
      {...this.props.table as any}
      columns={cols}
      dataSource={this.state.data}
      rowSelection={rowSelection}
    />;
    if (dropdownFilter) {
      return (
        table
      );
    }
    else {
      return (<React.Fragment>
        <Input
          placeholder="Search"
          value={this.state.searchText}
          onChange={this.inputSearchChange} />
        {table}
      </React.Fragment>
      );
    }

  }
  private inputSearchChange = (e: any) => {
    const currValue = e.target.value;
    this.setState({ searchText: currValue });
    const dataIndex = String(this.props.columns[0].dataIndex);
    const data = this.getOptions(this.props);
    const filteredData = data
      .filter(entry =>
        entry[dataIndex].toLowerCase().includes(currValue.toLowerCase())
      );
    this.setState({ data: filteredData });

  };

  private onCheck = (selectedRowKeys: any[]) => {
    if (this.props.disabled) {
      return;
    }
    const keyMap: any = {};
    selectedRowKeys.forEach((item) => (keyMap[item] = true));
    const data = this.getOptions(this.props);
    const selectedData = data.filter((item: any) => keyMap[item.ID]);
    this.setState({ selectedRowKeys:selectedRowKeys });
    if (this.props.onChange) {
      this.props.onChange(selectedData);
    }
  };

  private getOptions(props: Props<any>) {
    const { options, optionsAsync } = props;
    let data = options;
    if (!data && optionsAsync) {
      data = optionsAsync();
    }
    return data as any[];
  }

  private handleSearch = (selectedKeys: any[], confirm: any) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
}

export default CheckTable;
