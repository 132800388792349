import React from "react";
import css from "@emotion/css";

import moment from "moment";
import { Notification } from "../../../../data/models/Notification";
import { Button } from "antd";
import { read } from "../../../../data/api/notifications";
import { CheckOutlined } from "@ant-design/icons";
import { LanguageContext } from "../../../../contexts/LanguageContext";
import { Link } from "react-router-dom";

interface Props {
  data: Notification[];
  count: number;
  onRead: (index: number, item: Notification) => void;
}

export default class PopupContent extends React.Component<Props, any> {
  public static contextType = LanguageContext;

  public render() {
    const seeAll = (
      <Link to={"/notifications"}>
        <div css={seeAllTxt}>See all</div>
      </Link>
    );

    if (this.props.count) {
      return (
        <div css={listCss}>
          {this.props.data.map((i: Notification, index: number) => {
            return (
              <div key={i.ID} css={itemCss}>
                <div css={readCss}>
                  <Button
                    name={i.ID + "-" + index}
                    shape="circle"
                    type="dashed"
                    icon={<CheckOutlined />}
                    size="small"
                    onClick={this.onClick}
                  />
                </div>
                <div css={titleCss}>{i.Title}</div>
                <div css={dateCss}>
                  {moment(i.CreatedAt).format("MM/DD/YYYY HH:mm:ss")}
                </div>
                <span>{i.Body}</span>
              </div>
            );
          })}
          {seeAll}
        </div>
      );
    } else {
      return (
        <div>
          <div css={noRecTxt}>{this.context["noRecords"]}</div>
          {seeAll}
        </div>
      );
    }
  }

  public shouldComponentUpdate(nextProps: Props) {
    return this.props.count !== nextProps.count;
  }

  private onClick = (e: any) => {
    const [name, index] = e.currentTarget.name.split("-");
    read({ ID: name })
      .then((resp: Notification) => {
        this.props.onRead(index, resp);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };
}

export const listCss = css`
  overflow: auto;
  max-height: 80vh;
  margin: 0px -16px;
  padding: 0px 16px;
`;

export const itemCss = css`
  border-bottom: 1px solid lightgray;
  font-size: 12px;
  padding: 4px 0px;
  max-width: 200px;
`;
export const titleCss = css`
  font-weight: bold;
`;
export const dateCss = css`
  font-size: 9px;
  color: gray;
`;
export const readCss = css`
  float: right;
`;
export const seeAllTxt = css`
  text-align: center;
  padding: 10px 0 0 0;
  font-weight: bold;
`;
export const noRecTxt = css`
  padding: 0 0 10px 0;
  border-bottom: 1px solid lightgray;
`;
