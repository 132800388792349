import validation from "../../utils/messages/validation";
import { Country } from "./Country";

export interface Region {
  ID: number;
  Name: string;
  PhoneCodes: string;
  IPs: string;
  Countries: Country[];
}

export const RegionProps = {
  Name: {
    rules: [validation.required, validation.minMax(2, 30)],
  },
};
