import { Alert } from "antd";

export interface Props {
  initialValue?: any;
  value?: any;
  type?: "success" | "info" | "warning" | "error";
}


export default function Description(props: Props) {
  return (
    <Alert style={{ width: "max-content", maxWidth: "100%" }}
      // showIcon
      message={props.value ?? props.initialValue}
      type={props.type} />
  );
};

