import React from "react";
import { Input, Form } from "antd";
import { PartnerProps } from "../../../data";

const Step2 = React.forwardRef((props: any, ref: any) => {
  return (
    <React.Fragment>
      <Form
        form={props.form}
        style={{
          textAlign: "center",
          width: "40%",
          margin: "auto",
          marginTop: 32,
        }}
      >
        <Form.Item name="Name" rules={PartnerProps.Name.rules}>
          <Input placeholder="Lead Buyer Name" />
        </Form.Item>
        <Form.Item
          name="Title"
          rules={[
            {
              required: true,
              message: "Contact Title is required.",
            },
          ]}
        >
          <Input placeholder="Contact Title" />
        </Form.Item>
        <Form.Item name="FirstName" rules={PartnerProps.FirstName.rules}>
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item name="LastName" rules={PartnerProps.LastName.rules}>
          <Input placeholder="Last Name" />
        </Form.Item>
        <Form.Item name="ContactEmail" rules={PartnerProps.Email.rules}>
          <Input placeholder="Contact Email" type="email" />
        </Form.Item>
        <Form.Item name="Phone" rules={PartnerProps.Phone.rules}>
          <Input placeholder="Phone" type="phone" />
        </Form.Item>
      </Form>
    </React.Fragment>
  );
});

export default Step2;
