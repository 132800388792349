import validation from "../../utils/messages/validation";
import BaseModel from "./BaseModel";

export interface Parameter extends BaseModel {
  Name: string;
  Value: string;
  Description: string;
}
export const ParameterProps = {
  Value: {
    rules: [validation.required, validation.minMax(1, 30)],
  },
  Description: {
    rules: [validation.required, validation.minMax(1, 300)],
  },
};
