import { Permission } from "./Permission";
import validation from "../../utils/messages/validation";
import BaseModel from "./BaseModel";

export interface Role extends BaseModel {
  Name: string;
  Permissions: Permission[];
}
export const RoleProps = {
  Name: {
    rules: [validation.required, validation.minMax(5, 50)],
  },
};

export enum Roles{
  Admin=1,
  Operator=2,
  Publisher=3,
  BuyerID=4
}
