export enum Timezone {
  EST = 0,
  CST,
  MST,
  PST,
  AKST,
  HST,
}
export const Timezones = [
  { ID: Timezone.EST, Name: "EST/(UTC-5)" },
  { ID: Timezone.CST, Name: "CST/(UTC-6)" },
  { ID: Timezone.MST, Name: "MST/(UTC-7)" },
  { ID: Timezone.PST, Name: "PST/(UTC-8)" },
  { ID: Timezone.AKST, Name: "AKST/(UTC-9)" },
  { ID: Timezone.HST, Name: "HST/(UTC-10)" },
];
