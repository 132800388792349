import { load, IReq, QApi, qapi2Url } from "./index";
import { MappingField } from "../models/MappingField";
import { Field } from "../models/Field";

export const list = (
  leadOrderID: number | string,
  req?: any,
  api?: QApi
): Promise<MappingField[]> =>
  load(
    `leadOrders/${leadOrderID}/mappingFields${qapi2Url(api)}`,
    "GET",
    false,
    undefined,
    api
  );
export const defaultFields = (
  leadOrderID: number | string,
  req?: any,
  api?: QApi
): Promise<Field[]> =>
  load(
    `leadOrders/${leadOrderID}/mappingFields/defaultFields${qapi2Url(api)}`,
    "GET",
    false,
    undefined,
    api
  );
export const create = (
  leadOrderID: number | string,
  data: MappingField
): Promise<MappingField> =>
  load(`leadOrders/${leadOrderID}/mappingFields`, "POST", true, data);
export const read = (
  leadOrderID: number | string,
  req: IReq
): Promise<MappingField> =>
  load(
    `leadOrders/${leadOrderID}/mappingFields/${req.ID}`,
    "GET",
    true,
    undefined
  );
export const update = (
  leadOrderID: number | string,
  req: IReq,
  data: MappingField
): Promise<MappingField> =>
  load(`leadOrders/${leadOrderID}/mappingFields/${req.ID}`, "PUT", true, data);
export const del = (
  leadOrderID: number | string,
  req: IReq
): Promise<MappingField> =>
  load(
    `leadOrders/${leadOrderID}/mappingFields/${req.ID}`,
    "DELETE",
    true,
    undefined
  );

export const add = (
  leadOrderID: number | string,
  data: Field[]
): Promise<MappingField> =>
  load(`leadOrders/${leadOrderID}/mappingFields/add`, "POST", true, data);
