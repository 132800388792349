import { DataSource } from ".";
import { list, read, create } from "../api/ipGeoRequests";
import { IPGeoRequest } from "../models/IPGeoRequest";
export const IPGeoRequestsDS: DataSource<IPGeoRequest> = {
  list,
  read,
  create: create,
  update: () => { throw "Not implemented"; }, //eslint-disable-line
  del: () => { throw "Not implemented"; }, //eslint-disable-line
};

export default IPGeoRequestsDS;
