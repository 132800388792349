import validation from "../../utils/messages/validation";
import { FieldType } from "./FieldType";

export interface Field {
  ID: number;
  Name: string;
  Label: string;
  Type: FieldType;
  MaxLength: number;
  MinLength: number;
  Required: boolean;
  Default: string;
  CampaignID: number;
}

export const FieldProps = {
  Name: { rules: [validation.required, validation.minMax(1, 50)] },
  Label: { rules: [validation.required, validation.minMax(1, 50)] },
  Type: { rules: [validation.required] },
  Default: { rules: [validation.max(100)] },
  CampaignID: { rules: [validation.required] },
};
