import { IReq, load, QApi, qapi2Url } from "./index";
import { CampaignReport } from "../models/CampaignReport";
import { BuyerReport } from "../models/BuyerReport";
import { PublisherReport } from "../models/PublisherReport";
import { CategoryReport } from "../models/CategoryReport";
import { UnsoldLeadsReport } from "../models/UnsoldLeadsReport";
import { RejectedCampaignReport } from "../models/RejectedCampaignReport";
import { PublisherSubIDReport } from "../models/PublisherSubIDReport";
import { buyerClickType, clickType } from "../../pages/report/Report.common";

export const campaignReports = (req?: any, api?: QApi): Promise<CampaignReport[]> =>
  load(`reports/campaign${qapi2Url(api)}`, "GET", true, undefined, api);
export const buyerReports = (req?: any, api?: QApi): Promise<BuyerReport[]> =>
  load(`reports/buyer${qapi2Url(api)}`, "GET", true, undefined, api);
export const publisherReport = (req?: any, api?: QApi): Promise<PublisherReport[]> =>
  load(`reports/publisher${qapi2Url(api)}`, "GET", true, undefined, api);
export const categoryReport = (req?: any, api?: QApi): Promise<CategoryReport[]> =>
  load(`reports/category${qapi2Url(api)}`, "GET", true, undefined, api);
export const campaignPublisherReport = (publisherID: number | string, req?: IReq, api?: QApi): Promise<CampaignReport[]> =>
  load(`reports/publisher/${publisherID}/campaign${qapi2Url(api)}`, "GET", true, undefined, api);
export const currentPublisherCampaignReport = (req?: IReq, api?: QApi): Promise<CampaignReport[]> =>
  load(`reports/publisher/campaign${qapi2Url(api)}`, "GET", true, undefined, api);
export const categoryCampaignReport = (categoryID: number | string, req?: IReq, api?: QApi): Promise<CampaignReport[]> =>
  load(`reports/category/${categoryID}/campaign${qapi2Url(api)}`, "GET", true, undefined, api);
export const buyerCategoryReport = (buyerID: number | string, req?: IReq, api?: QApi): Promise<BuyerReport[]> =>
  load(`reports/buyer/${buyerID}/category${qapi2Url(api)}`, "GET", true, undefined, api);
export const buyerCampaignReport = (buyerID: number | string, categoryID: number, req?: IReq, api?: QApi): Promise<BuyerReport[]> =>
  load(`reports/buyer/${buyerID}/category/${categoryID}/campaign${qapi2Url(api)}`, "GET", true, undefined, api);
export const leadOrderReport = (buyerID: number, categoryID: number, req?: IReq, api?: QApi): Promise<BuyerReport[]> =>
  load(`reports/buyer/${buyerID}/category/${categoryID}/leadOrder${qapi2Url(api)}`, "GET", true, undefined, api);
export const unsoldLeadsCampaignReport = (req?: IReq, api?: QApi): Promise<UnsoldLeadsReport[]> =>
  load(`reports/unsold/campaign${qapi2Url(api)}`, "GET", true, undefined, api);
export const unsoldLeadsLeadOrderReport = (req?: IReq, api?: QApi): Promise<UnsoldLeadsReport[]> =>
  load(`reports/unsold/leadorder${qapi2Url(api)}`, "GET", true, undefined, api);
export const rejectedCampaignReport = (req?: IReq, api?: QApi): Promise<RejectedCampaignReport[]> =>
  load(`reports/rejected${qapi2Url(api)}`, "GET", true, undefined, api);
export const getCampaignPublisherReport = (campaignID: number, req?: any, api?: QApi): Promise<PublisherReport[]> =>
  load(`reports/campaign/${campaignID}/publisher${qapi2Url(api)}`, "GET", true, undefined, api);
export const getCampaignLandingPageReport = (campaignID: number, req?: any, api?: QApi): Promise<PublisherReport[]> =>
  load(`reports/campaign/${campaignID}/landingPage${qapi2Url(api)}`, "GET", true, undefined, api);
export const getCampaignCreativeReport = (campaignID: number, req?: any, api?: QApi): Promise<PublisherReport[]> =>
  load(`reports/campaign/${campaignID}/creative${qapi2Url(api)}`, "GET", true, undefined, api);
export const getPublisherCampaignSubIDReport = (campaignID: number, publisherID: number | undefined, req?: IReq, api?: QApi): Promise<PublisherSubIDReport[]> =>
  load(`reports/publisher/${publisherID}/campaign/${campaignID}/subID${qapi2Url(api)}`, "GET", true, undefined, api);
export const getCampaignLeadString = (campaignID: number, type: clickType, req?: any, api?: QApi): Promise<string> =>
  load(`reports/campaign/${campaignID}/leadString/${type}${qapi2Url(api)}`, "GET", true, undefined, api);
export const getCampaignPublisherLeadString = (campaignID: number, publisherID: number, type: clickType, req?: any, api?: QApi): Promise<string> =>
  load(`reports/campaign/${campaignID}/publisher/${publisherID}/leadString/${type}${qapi2Url(api)}`, "GET", true, undefined, api);
export const getPublisherLeadString = (publisherID: number, type: clickType, data: any, api?: QApi): Promise<string> =>
  load(`reports/publisher/${publisherID}/leadString/${type}${qapi2Url(api)}`, "POST", true, data, api);
export const getCategoryLeadString = (categoryID: number, type: clickType, req?: any, api?: QApi): Promise<string> =>
  load(`reports/category/${categoryID}/leadString/${type}${qapi2Url(api)}`, "GET", true, undefined, api);
export const getBuyerLeadString = (buyerID: number, type: buyerClickType, req?: any, api?: QApi): Promise<string> =>
  load(`reports/buyer/${buyerID}/leadString/${type}${qapi2Url(api)}`, "GET", true, undefined, api);
export const getBuyerCategoryLeadString = (buyerID: number, categoryID: number, type: buyerClickType, req?: any, api?: QApi): Promise<string> =>
  load(`reports/buyer/${buyerID}/category/${categoryID}/leadString/${type}${qapi2Url(api)}`, "GET", true, undefined, api);
export const getBuyerCategoryCampaignLeadString = (buyerID: number, categoryID: number, campaignID: number, type: buyerClickType, req?: any, api?: QApi): Promise<string> =>
  load(`reports/buyer/${buyerID}/category/${categoryID}/campaign/${campaignID}/leadString/${type}${qapi2Url(api)}`, "GET", true, undefined, api);
  export const getBuyerCategoryLeadOrderLeadString = (buyerID: number, categoryID: number, leadOrderID: number, type: buyerClickType, req?: any, api?: QApi): Promise<string> =>
  load(`reports/buyer/${buyerID}/category/${categoryID}/leadOrder/${leadOrderID}/leadString/${type}${qapi2Url(api)}`, "GET", true, undefined, api);