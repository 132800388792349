
import { DataSource } from ".";
import { list } from "../api/payoutExceptionHistory";
import { PayoutExceptionHistory } from "../models/PayoutExceptionHistory";

export const PayoutExceptionHistoryDS = (publisherID: number): DataSource<PayoutExceptionHistory> => ({
  list: list.bind(undefined, publisherID),
  create: () => { throw "Not implemented"; }, //eslint-disable-line
  read: () => { throw "Not implemented"; }, //eslint-disable-line
  update: () => { throw "Not implemented"; }, //eslint-disable-line
  del: () => { throw "Not implemented"; }, //eslint-disable-line
});


export default PayoutExceptionHistoryDS;
