export enum CreativeType {
  Image = 1,
  Html,
  Text,
  Link,
  Html_Image,
}

export const CreativeTypeArray = [
  { ID: 1, Name: "Image" },
  { ID: 2, Name: "Html" },
  { ID: 3, Name: "Text" },
  { ID: 4, Name: "Link" },
  { ID: 5, Name: "Html Image" },
];
