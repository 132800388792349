import { DataSource } from ".";
import { list, create, read, update, del } from "../api/emailTemplates";
import { EmailTemplate } from "../models/EmailTemplate";
export const EmailTemplatesDS: DataSource<EmailTemplate> = {
  list,
  create,
  read,
  update,
  del,
};

export default EmailTemplatesDS;
