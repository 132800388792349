import { DataSource } from "./index";
import { create, read, update, del, tree } from "../api/categories";
import { Category } from "../models/Category";
export const CategoriesDS: DataSource<Category> = {
  list: tree,
  create,
  read,
  update,
  del,
};

export default CategoriesDS;
