import validation from "../../utils/messages/validation";
import { Campaign } from "./Campaign";
import { DataEntryField } from "./DataEntryField";
import { Publisher } from "./Publisher";

export interface DataEntry {
  ID: number;
  CampaignID: number;
  PublisherID: number;
  IsActive: boolean;
  Script: string;
  Token:string;
  Url:string;
  DataEntryFields: DataEntryField[];
  Publisher:Publisher;
  Campaign:Campaign;
}

export const DataEntryProps = {
  CampaignID: {
    rules: [validation.required],
  },
  PublisherID: {
    rules: [validation.required],
  },
};
