import { load, IReq, QApi, qapi2Url } from "./index";
import { Validation } from "../models/Validation";

export const list = (req?: any, api?: QApi): Promise<Validation[]> =>
  load(`validations${qapi2Url(api)}`, "GET", false, undefined, api);
  export const create = (data: Validation): Promise<Validation> =>
  load(`validations`, "POST", true, data);
  export const read = (req: IReq): Promise<Validation> =>
  load(`validations/${req.ID}`, "GET", true, undefined);
  export const update = (req: IReq, data: Validation): Promise<Validation> =>
  load(`validations/${req.ID}`, "PUT", true, data);
  export const del = (req: IReq): Promise<Validation> =>
  load(`validations/${req.ID}`, "DELETE", true, undefined);
  
  export const functions = (req?: any, api?: QApi): Promise<string[]> =>
    load(`validations/functions`, "GET", false, undefined, api);