import { API } from "./index";

export const post = (pathParam: string, data: any): Promise<Response> =>
fetch(API + `public/integration/${pathParam}`, {
  headers:{ "Content-Type": "application/json"},
  method:"POST",
  credentials: "include",
  body: JSON.stringify(data),
});

export const postTest = (uPath: string, data: any): Promise<Response> =>
fetch(API + `public/integration/test/${uPath}`, {
  headers:{ "Content-Type": "application/json"},
  method:"POST",
  credentials: "include",
  body: JSON.stringify(data),
});

export interface IntegrationHTTPResponse {
  Code: number;
  Message: string;
  ID?: number;
}
export interface IntegrationTestHTTPResponse {
  Code: number;
  Message: string;
  Data: { [key: string]: any; };

}

