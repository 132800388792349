import { DataSource } from ".";
import { list, create, read, update, del } from "../api/parameters";
import { Parameter } from "../models/Parameter";
export const ParametersDS: DataSource<Parameter> = {
  list,
  create,
  read,
  update,
  del,
};

export default ParametersDS;
