import { Field } from "..";
import validation from "../../utils/messages/validation";
import BaseModel from "./BaseModel";

export interface PixelFilter extends BaseModel {
  Operation?: number;
  Value?: string
  PublisherDealID?: number;
  FieldID?: number;
  Field?: Field;
}

export const PixelFilterOperations = [
  { ID: 1, Name: "In" },
  { ID: 2, Name: "Not In" },
  { ID: 3, Name: "=" },
  { ID: 4, Name: ">" },
  { ID: 5, Name: "<" }
];

export const PixelFilterProps = {
  Operation: { rules: [validation.required] },
  PublisherDealID: { rules: [validation.required] },
  FieldID: { rules: [validation.required] },
  Value: { rules: [validation.required] }

};