import { DataSource } from ".";
import { list, create, read, update, del } from "../api/campaignPublisherDeals";
import { PublisherDeal } from "../models/PublisherDeal";

export const CampaignPublisherDealsDS = (campaignID: string | number): DataSource<PublisherDeal> => ({
  list:list.bind(undefined,campaignID),
  create:create.bind(undefined,campaignID),
  read:read.bind(undefined,campaignID),
  update:update.bind(undefined,campaignID),
  del:del.bind(undefined,campaignID),
});


export default CampaignPublisherDealsDS;
