import { DataSource } from ".";
import { list, create, read, update, del } from "../api/validations";
import { Validation } from "../models/Validation";
export const ValidationsDS: DataSource<Validation> = {
  list,
  create,
  read,
  update,
  del,
};

export default ValidationsDS;
