import { DataSource } from ".";
import { list, create, read, update, del } from "../api/campaignFields";
import { Field } from "../models/Field";
export const CampaignFieldsDS = (campaignID: string | number): DataSource<Field> => ({
  list: list.bind(undefined, campaignID),
  create: create.bind(undefined, campaignID),
  read: read.bind(undefined, campaignID),
  update: update.bind(undefined, campaignID),
  del: del.bind(undefined, campaignID),
});

export default CampaignFieldsDS;

