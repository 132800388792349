import validation from "../../utils/messages/validation";
import BaseModel from "./BaseModel";

export interface Validation extends BaseModel {
  Name: string;
  Function: string;
  Field: string;
  Value: string;
  Message: string;
  Flag: boolean;
  CampaignID: number;
  OmitEmpty: boolean;
}

export const ValidationProps = {
  Name: {
    rules: [validation.required],
  },
  Function: {
    rules: [validation.required],
  },
  Field: {
    rules: [validation.max(50)],
  },
  Value: {
    rules: [validation.max(500)],
  },
  Message: {
    rules: [validation.max(500)],
  },
};
