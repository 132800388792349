import { DataSource } from ".";
import { list, create, read, update, del } from "../api/zipCodes";
import { ZIPCode } from "../models/ZIPCode";
export const ZIPCodesDS: DataSource<ZIPCode> = {
  list,
  create,
  read,
  update,
  del,
};

export default ZIPCodesDS;
