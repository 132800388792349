export function findInTree(
  items: any[],
  id: any,
  key: string,
  childrenKey: string = "Children",
): any {
  let i = 0;
  let found = false;

  for (; i < items.length; i++) {
    if (items[i][key] === id) {
      return items[i];
    } else if (Array.isArray(items[i][childrenKey])) {
      found = findInTree(items[i][childrenKey], id, key, childrenKey);
      if (found) {
        return found;
      }
    }
  }
}
