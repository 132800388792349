import React from "react";
import { getWithFlatKey } from "../../utils/collections/maps";
import { Row } from "antd";
import { css } from "@emotion/core";
import isEqual from "react-fast-compare";

export interface Props {
  title?: string;
  value?: any;
  labelField?: string;
  disabled?: boolean;
}

/**
 * Creates a field which shows info and mostly disabled due @param disabled prop
 * @param title - Title for given space
 * @param value - Value for given space
 * @param labelField - Label on front for the given space
 * @param disabled
 *
 */

const FieldInfo = (props: Props) => {
  let { title, value, labelField, disabled } = props;
  if (labelField) {
    value = labelField
      .split(",")
      .map((key: string) => getWithFlatKey(value, key))
      .join(" ");
  }
  if (typeof value === "number") {
    value = value.toFixed(2);
  }
  return (
    <Row css={disabled && disabledRowCss}>
      {title && <label title={title}>{title}</label>}
      <div css={disabled ? disabledInfoCss : infoCss}>{value}</div>
    </Row>
  );
};

export default React.memo(FieldInfo, isEqual);

const disabledRowCss = css`
  height: 34px;
  transition: all 0.3s linear;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding-left: 11px;
  margin-top: 4px;
`;
const disabledInfoCss = css`
  transition: all 0.3s linear;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  margin-top: -4px;
  overflow: auto;
`;
const infoCss = css`
  font-size: 16px;
  height: 30px;
  transition: all 0.3s linear;
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 0px;
  padding: 3px 11px 3px;
  font-weight: bold;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
`;
