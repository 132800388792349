import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ConfigProvider } from "antd";
import { LanguageContext } from "./contexts/LanguageContext";
import en_US from "antd/lib/locale-provider/en_US";
import en from "./locales/en.json";

ReactDOM.render(
  <ConfigProvider locale={en_US}>
    <LanguageContext.Provider value={en}>
      <App />
    </LanguageContext.Provider>
  </ConfigProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
