import { IReq, load, QApi, qapi2Url } from ".";
import { PayoutException } from "../models/PayoutException";

export const list = (campaignID: number, publisherDealID: number, req?: any, api?: QApi): Promise<PayoutException[]> =>
  load(`campaigns/${campaignID}/publisherDeals/${publisherDealID}/payoutExceptions${qapi2Url(api)}`, "GET", false, undefined, api);
export const create = (campaignID: number, publisherDealID: number, data: PayoutException): Promise<PayoutException> => {
  data.PublisherDealID = publisherDealID;
  return load(`campaigns/${campaignID}/publisherDeals/${publisherDealID}/payoutExceptions`, "POST", true, data);
}
export const read = (campaignID: number, publisherDealID: number, req: IReq): Promise<PayoutException> =>
  load(`campaigns/${campaignID}/publisherDeals/${publisherDealID}/payoutExceptions/${req.ID}`, "GET", true, undefined);
export const update = (campaignID: number, publisherDealID: number, req: IReq, data: PayoutException): Promise<PayoutException> => {
  data.PublisherDealID = publisherDealID;
  return load(`campaigns/${campaignID}/publisherDeals/${publisherDealID}/payoutExceptions/${req.ID}`, "PUT", true, data);
}
export const del = (campaignID: number, publisherDealID: number, req: IReq): Promise<PayoutException> =>
  load(`campaigns/${campaignID}/publisherDeals/${publisherDealID}/payoutExceptions/${req.ID}`, "DELETE", true, undefined);