import validation from "../../utils/messages/validation";
import BaseModel from "./BaseModel";

export interface Partner extends BaseModel {
  Name: string;
  Title: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  Phone2: string;
  Fax: string;
  RepresentativeID: number;
  Address: string;
  Address2: string;
  CountryID: number;
  City: string;
  State: string;
  Zip: string;
  TaxID: string;
  SignUpDate: string;
  SignUpIP: string;
  ContactEmail: string;
  Status: PartnerStatus;
  NotifyLimitPercentage: number;
}

export const PartnerProps = {
  Name: {
    rules: [validation.required, validation.minMax(2, 50)],
  },
  Title: { rules: [validation.required, validation.minMax(2, 50)] },
  FirstName: {
    rules: [
      validation.required,
      validation.minMax(2, 50),
      validation.pattern(
        "^[a-zA-Z]+$",
        "First name must include only letters.",
      ),
    ],
  },
  LastName: {
    rules: [
      validation.required,
      validation.minMax(2, 50),
      validation.pattern("^[a-zA-Z]+$", "Last name must include only letters."),
    ],
  },
  Email: {
    rules: [
      validation.required,
      validation.pattern(
        "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
        "Email needs to be valid.",
      ),
    ],
  },
  Phone: {
    rules: [
      validation.required,
      validation.pattern(
        "^(\\+\\d{1,3}( )?)?((\\(\\d{3}\\))|\\d{3})[- .]?\\d{3}[- .]?\\d{4}$",
        "Phone must be valid.",
      ),
    ],
  },
  Address: { rules: [validation.required] },
  CountryID: { rules: [validation.required] },
  City: { rules: [validation.required] },
  State: { rules: [validation.required] },
  Status: { rules: [validation.required] },
  Zip: {
    rules: [
      validation.required,
      validation.pattern("^[0-9]{5}$", "Zip code must be valid."),
    ],
  },
};

export enum PartnerStatus {
  Pending = 1,
  Inactive,
  Active,
}

export const PartnerStatusArray = [
  { ID: PartnerStatus.Active, Name: "Active" },
  { ID: PartnerStatus.Inactive, Name: "Inactive" },
  { ID: PartnerStatus.Pending, Name: "Pending" },
];
