import { css } from "@emotion/core";
import { Alert, Button, Col, Row, } from "antd";
import React from "react";
import { DataEntry, DataEntryField, DataEntryFieldType, Field, FieldType } from "../../data";
import { defaultLabelsWithAsync } from "../../utils/components/select";
import { rightCss, centerCss } from "../../components/background/HalfRed.styles";
import { getDataEntry } from "../../data/api/dataEntry";
import { useHistory } from "react-router-dom";
import AutoForm, { ModelProps } from "../../components/form/AutoForm";
import validation from "../../utils/messages/validation";
import { post } from "../../data/api/integration";

const DataEntryForm = (props: any) => {
  const [dataEntry, setDataEntry] = React.useState<DataEntry>();;
  const history = useHistory();
  const formRef = React.useRef<AutoForm>(null);
  const [response, setResponse] = React.useState<string>();
  const [error, setError] = React.useState(false);

  const setErrorAndMesage = React.useCallback((error: boolean, promise: Promise<string>) => {
    promise.then((resp) => {
      setResponse(resp);
      setError(error);
    });
  }, []);
  const onSubmit = React.useCallback((values: any) => {
    //remove empty values
    Object.keys(values).forEach(function (key) {
      if (values[key] === 'undefined' || values[key] === null || values[key] === "") {
        delete values[key];
      }
    });
    post(dataEntry?.Url!, values)
      .then(resp => {
        if (resp.ok){
          setErrorAndMesage(false,resp.text());
        }
        else
        setErrorAndMesage(true,resp.text());
    
      
      })
      .catch(resp => {
        setErrorAndMesage(true,resp.text());
      });

  }, [dataEntry?.Url,setErrorAndMesage]);

  const submitClicked = React.useCallback(() => {
    formRef.current?.formRef.current?.submit();
  }, []);
  const dateOnChange = React.useCallback((date: any, dateString: string, fieldname: string) => {
    const obj: any = {};
    obj[fieldname] = dateString;
    formRef.current?.formRef.current?.setFieldsValue(obj);
  }, []);

  React.useEffect(() => {
    const id = props.match.params.id;
    const token = props.match.params.token;
    getDataEntry(token, { ID: id })
      .then(d => setDataEntry(d))
      .catch(_ => history.push("/"));
  }, [props.match.params.id, props.match.params.token, history]);

  type CallbackType = (dataEntryFields: DataEntryField[]) => [ModelProps<DataEntryField>[], { [key: string]: any; }]

  const fields2Model = React.useCallback<CallbackType>((dataEntryFields: DataEntryField[]) => {
    const data: { [key: string]: any; } = {};
    //sort
    const fields = dataEntryFields.slice(0).sort(function (a: DataEntryField, b: DataEntryField) {
      return a.SortOrder - b.SortOrder;
    });
    return [fields.map(m => {
      data[m.Field.Name] = m.Default;
      let inputProps: any = {};
      let inputType: any = "";
      const options = m.Options.split("\n").map(s => { return { ID: s, Name: s } });
      if (m.Field.Name === "CampaignID" || m.Field.Name === "PublisherID" || m.Field.Name === "SourceID")
        inputType = "hidden";
      else
        switch (m.Type) {
          case DataEntryFieldType.Checkbox:
            if (options.length > 1) {
              inputType = "checkboxgroup";
              inputProps = {
                options: options.map(x => x.ID)
              }
            }
            else {
              inputType = "checkbox";
            }

            break;
          case DataEntryFieldType.DropdownList:
            inputType = "select";
            inputProps = { ...defaultLabelsWithAsync(() => options) }
            break;
          case DataEntryFieldType.MultiSelect:
            inputType = "checkTableWithinModal";
            inputProps = {
              options: options,
              table: { scroll: { y: 240, x: true } },
              columns: [
                {
                  title: "Name",
                  dataIndex: "Name",
                  inputType: "text",
                },
              ],

            }
            break;
          case DataEntryFieldType.RadioButton:
            inputType = "radio";
            inputProps = {
              options: options.map(x => x.ID)
            }
            break;
          case DataEntryFieldType.TextArea:
            inputType = "textarea";
            inputProps = {
              style: { width: "100%" }
            }
            break;
          case DataEntryFieldType.TextBox:
            if (m.Field.Type === FieldType.Number) {
              inputType = "number";
            }
            else {
              inputType = "text";
            }

            break;
          case DataEntryFieldType.Date:
            inputType = "datepicker";
            inputProps = {
              onChange: (date: any, dateString: string) => dateOnChange(date, dateString, m.Field.Name),
              format: "YYYY-MM-DD"
            }
            break;
          case DataEntryFieldType.DateTime:
            inputType = "datepicker";
            inputProps = {
              onChange: (date: any, dateString: string) => dateOnChange(date, dateString, m.Field.Name),
              format: "YYYY-MM-DD HH:mm:ss"
            }
            break;
          default:
            inputType = "text";

        }
      return {
        inputType: inputType,
        dataIndex: m.Field.Name,
        inputProps: inputProps,
        title: m.Label,
        fieldDecoratorOptions: { rules: field2Validation(m.Field) },
      };
    }), data];
  }, [dateOnChange]);


  const title = React.useMemo(() => `Data Entry : ${dataEntry?.Campaign.Name} : ${dataEntry?.Publisher.Name}`, [dataEntry]);
  const [inputs, data] = React.useMemo(() => dataEntry ? fields2Model(dataEntry?.DataEntryFields) : [], [dataEntry, fields2Model]);
  return dataEntry ? (<div css={centerCss}>
    <div css={rightCss}>
      <img src="logo1.png" alt="logo" css={iconCss} />
      <div css={containerCss}>
        <div css={titleCss}>{title}</div>
        {response &&
          <Alert message={response}
            type={error === true ? "error" : "success"} closable onClose={() => setResponse(undefined)} />
        }

        <Row gutter={24} style={{ marginTop: "20px" }}>
          <Col span={11}>
            <Row gutter={24}>
              <AutoForm ref={formRef} model={inputs} labelCol={{ sm: 10 }} wrapperCol={{ sm: 12 }} style={{ width: "100%" }} data={data}
                submitIfValid={onSubmit} />

            </Row>
            <Row gutter={24}>
              <Col span={22}><Button type="primary" onClick={submitClicked} style={{ float: "right" }}>Submit</Button></Col>
            </Row>

          </Col>
          <Col span={13}>
            <b style={{ fontSize: "17px" }}>Script:</b> <br />
            <div dangerouslySetInnerHTML={{ __html: dataEntry.Script }} />
          </Col>
        </Row>
      </div>
    </div>
  </div>
  ) : (
    <div></div>
  )

}
export default DataEntryForm;

export const containerCss = css`
width: 90%;
background-color: whitesmoke;
padding: 20px;
box-shadow: 1px 1px 10px black;
overflow-y: scroll; 
height:800px;
`;
const iconCss = css`
  width: 200px;
  height: 114px;
  margin-top: 15px;
`;
export const titleCss = css`
  width: 100%;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bolder;
  line-height: 1.3;
  display: flex;
  justify-content: center;
`;



const field2Validation = (f: Field) => {
  const vals = [];
  if (f.Required) {
    vals.push(validation.required);
  }
  if (f.MaxLength > 0) {
    vals.push(validation.max(f.MaxLength, "integer"));
  }
  return vals;
};
