import moment from "moment";

export function formatMMDDYYYY(original: string): string {
  const d = moment(original);
  return d.format("MM/DD/YYYY");
}

export function formatMMDDYYYY_HHMMSS(original: string): string {
  const d = moment(original);
  return d.format("MM/DD/YYYY hh:mm:ss");
}
export function formatMMDDYYYY_HHMM(original: string): string {
  const d = moment(original);
  return d.format("MM/DD/YYYY hh:mm");
}
export function formatHHMM(original: string): string {
  const d = moment(original);
  return d.format("hh:mm");
}
export function formatHHMMSS(original: string): string {
  const d = moment(original);
  return d.format("hh:mm:ss");
}
export function formatMMDDYYYY_HHMMSSA(original: string): string {
  if (!original)
    return "";
  const d = moment(original);
  return d.format("MM/DD/YYYY hh:mm:ss A");
}
export const defaultDatePickerFormat = "MM/DD/YYYY";
export const datePickerFormatWithTime = "MM/DD/YYYY hh:mm:ss A";
export const datePickerFormatMMDDYYYY_HHMMA = "MM/DD/YYYY hh:mm A";
export const timeickerFormat = "h:mmA";