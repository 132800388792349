import { load, IReq, QApi, qapi2Url, API } from "./index";
import { Campaign } from "../models/Campaign";
import {
  PublisherDeal,
  PublisherDealCampaignDto,
} from "../models/PublisherDeal";
import { Field } from "../models/Field";
import { LandingPage } from "../models/LandingPage";

export const list = (req?: any, api?: QApi): Promise<Campaign[]> =>
  load(`campaigns${qapi2Url(api)}`, "GET", false, undefined, api);

export const listDeleted = (req?: any, api?: QApi): Promise<Campaign[]> =>
  load(`campaigns/deleted${qapi2Url(api)}`, "GET", false, undefined, api);
export const create = (data: Campaign): Promise<Campaign> =>
  load(`campaigns`, "POST", true, data);
export const read = (req: IReq): Promise<Campaign> =>
  load(`campaigns/${req.ID}`, "GET", true, undefined);
export const update = (req: IReq, data: Campaign): Promise<Campaign> =>
  load(`campaigns/${req.ID}`, "PUT", true, data);
export const updatePartial = (
  req: IReq,
  data: Partial<Campaign>,
): Promise<any> => load(`campaigns/${req.ID}`, "PATCH", true, data);
export const del = (req: IReq): Promise<Campaign> =>
  load(`campaigns/${req.ID}`, "DELETE", true, undefined);
export const publisherCampaigns = (
  req?: any,
  api?: QApi,
): Promise<Campaign[]> =>
  load(`campaigns/publisherDeal${qapi2Url(api)}`, "GET", false, undefined, api);
export const publisherSubscribedCampaigns = (
  req?: any,
  api?: QApi,
): Promise<Campaign[]> =>
  load(
    `campaigns/publisherDeal/subscribed${qapi2Url(api)}`,
    "GET",
    false,
    undefined,
    api,
  );
export const buyerCampaigns = (req?: any, api?: QApi): Promise<Campaign[]> =>
  load(`campaigns/buyer${qapi2Url(api)}`, "GET", false, undefined, api);
export const buyerSubscribedCampaigns = (
  req?: any,
  api?: QApi,
): Promise<Campaign[]> =>
  load(
    `campaigns/buyer/subscribed${qapi2Url(api)}`,
    "GET",
    false,
    undefined,
    api,
  );
export const campaignStatus = (req?: any, api?: QApi): Promise<any> =>
  load(`campaigns/status${qapi2Url(api)}`, "GET", false, undefined, api);
export const publisherSubscribedCampaignsById = (
  publisherID: number | string,
  req?: any,
  api?: QApi,
): Promise<Campaign[]> =>
  load(
    `campaigns/publisherDeal/publisher/${publisherID}/subscribed${qapi2Url(
      api,
    )}`,
    "GET",
    false,
    undefined,
    api,
  );
export const getPublisherCampaignDetail = (
  req: IReq,
): Promise<PublisherDealCampaignDto> =>
  load(`campaigns/${req.ID}/publisherDetail`, "GET", true, undefined);
export const campaignUnsubscribeListExport = (req?: any, api?: QApi) =>
  window.location.assign(
    `${API}campaigns/${req.ID}/unsubscribeList/export${qapi2Url(api)}`,
  );
export const fields = (ids: number[]): Promise<Field[]> =>
  load(`campaigns/fields?ids=${ids.join(",")}`, "GET", true, undefined);
export const landingPages = (ids: number[]): Promise<LandingPage[]> =>
  load(`campaigns/landingPages?ids=${ids.join(",")}`, "GET", true, undefined);
export const publisherDeals = (ids: number[]): Promise<PublisherDeal[]> =>
  load(`campaigns/publisherDeals?ids=${ids.join(",")}`, "GET", true, undefined);
export const copy = (req: IReq, data: Campaign): Promise<Campaign> =>
  load(`campaigns/${req.ID}/copy`, "POST", true, data);
