import { load, IReq, QApi, qapi2Url } from "./index";
import { User } from "../models/User";
import { Roles } from "../models/Role";

export const list = (req?: any, api?: QApi): Promise<User[]> =>
  load(`users${qapi2Url(api)}`, "GET", true, undefined, api);
export const create = (data: User): Promise<User> =>
  load(`users`, "POST", true, data);
export const read = (req: IReq): Promise<User> =>
  load(`users/${req.ID}`, "GET", true, undefined);
export const update = (req: IReq, data: User): Promise<User> =>
  load(`users/${req.ID}`, "PUT", true, data);
export const del = (req: IReq): Promise<User> =>
  load(`users/${req.ID}`, "DELETE", true, undefined);
export const accept = (req: IReq): Promise<User> =>
  load(`users/${req.ID}/accept`, "PUT", true, undefined);
export const decline = (req: IReq): Promise<User> =>
  load(`users/${req.ID}/decline`, "PUT", true, undefined);
export const activate = (req: IReq): Promise<User> =>
  load(`users/${req.ID}/activate`, "PUT", true, undefined);
export const block = (req: IReq): Promise<User> =>
  load(`users/${req.ID}/block`, "PUT", true, undefined);
export const stats = (): Promise<{ [key: string]: number; }> =>
  load(`users/stats`, "GET", true, undefined);
export const profile = (): Promise<User> =>
  load("users/profile", "GET", false, undefined);
export const userRole = (): Promise<Roles> =>
  load("users/role", "GET", false, undefined);
