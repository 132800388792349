import React from "react";
import { Input, Form } from "antd";
import Captcha from "../../../components/form/inputs/Captcha";
import { FormInstance } from "antd/lib/form";
import { PartnerProps } from "../../../data";
interface Props {
  setSubmitable: (
    recaptchaLoaded: boolean,
    recaptchaValid: boolean,
    recaptchaResponseKey: string,
  ) => void;
  form: FormInstance;
}

const Step3 = React.forwardRef((props: Props, ref: any) => {
  const recaptchaRef = React.useRef<any>();

  const verifyCallback = React.useCallback(
    (responseKey: string) => {
      if (props.setSubmitable) {
        props.setSubmitable(true, true, responseKey);
      }
    },
    [props],
  );

  return (
    <React.Fragment>
      <Form
        form={props.form}
        style={{
          textAlign: "center",
          width: "40%",
          margin: "auto",
          marginTop: 32,
        }}
      >
        <Form.Item
          name="Address"
          rules={[
            {
              required: true,
              message: "Address is required.",
            },
          ]}
        >
          <Input placeholder="Address" />
        </Form.Item>
        <Form.Item name="Address2">
          <Input placeholder="Address 2" />
        </Form.Item>
        <Form.Item
          name="City"
          rules={[
            {
              required: true,
              message: "City is required.",
            },
          ]}
        >
          <Input placeholder="City" />
        </Form.Item>
        <Form.Item
          name="State"
          rules={[
            {
              required: true,
              message: "State is required.",
            },
          ]}
        >
          <Input placeholder="State" />
        </Form.Item>
        <Form.Item name="Zip" rules={PartnerProps.Zip.rules}>
          <Input placeholder="Zip Code" type="number" />
        </Form.Item>
        <Form.Item>
          <Captcha
            captchaRef={recaptchaRef}
            onLoadCallback={() => {}}
            onExpiredCallback={() => recaptchaRef.current.reset()}
            verifyCallback={verifyCallback}
          />
        </Form.Item>
      </Form>
    </React.Fragment>
  );
});

export default Step3;
