export const defaultLabels: any = {
  valueField: "ID",
  labelField: "Name",
};

export function defaultLabelsWithAsync(optionsAsync: () => any) {
  return { ...defaultLabels, optionsAsync };
}
export function withAsync(
  valueField: string,
  labelField: string,
  optionsAsync: () => any,
) {
  return { valueField, labelField, optionsAsync };
}

export const defaultLabelsWithBoolean = {
  ...defaultLabels,
  options: [
    { ID: true, Name: "Active" },
    { ID: false, Name: "Inactive" }
  ],
};
export const booleanOptions = (t?: string, f?: string) => ({
  ...defaultLabels,
  options: [
    { ID: true, Name: t || "Yes" },
    { ID: false, Name: f || "No" }
  ],
});

