import validation from "../../utils/messages/validation";
import BaseModel from "./BaseModel";
import { LeadOrder } from "./LeadOrder";
import { Field } from "./Field";

export interface LeadOrderFilter extends BaseModel {
    Operation?: number;
    Value?: string;
    LeadOrderID?: number;
    FieldID?: number;
    LeadOrder?: LeadOrder;
    Field?: Field;
}
export const LeadOrderFilterOperations = [
    { ID: 1, Name: "In" },
    { ID: 2, Name: "Not In" },
    { ID: 3, Name: "=" },
    { ID: 6, Name: "!=" },
    { ID: 4, Name: ">" },
    { ID: 5, Name: "<" }
];
export const LeadOrderFilterProps = {
    Operation: { rules: [validation.required] },
    LeadOrderID: { rules: [validation.required] },
    FieldID: { rules: [validation.required] },
    Value: { rules: [validation.required] }

};