import { DataSource } from ".";
import { buyerCampaigns as list, create,read,update,del  } from "../api/campaigns";
import { Campaign } from "../models/Campaign";
export const BuyerCampaignsDS: DataSource<Campaign> = {
  list,
  create,
  read,
  update,
  del,
};

export default BuyerCampaignsDS;
