import { DataSource } from ".";
import { list, create, read, update, del } from "../api/campaignSuppressionLists";
import { SuppressionList } from "../models/SuppressionList";
export const CampaignSuppressionListsDS = (campaignID: string | number): DataSource<SuppressionList> => ({
  list: list.bind(undefined, campaignID),
  create: create.bind(undefined, campaignID),
  read: read.bind(undefined, campaignID),
  update: update.bind(undefined, campaignID),
  del: del.bind(undefined, campaignID),
});

export default CampaignSuppressionListsDS;

