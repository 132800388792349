import React, { CSSProperties, PropsWithChildren } from "react";
import { carouselCss, centerCss, leftCss, rightCss, } from "./HalfRed.styles";
import { Carousel } from "antd";
import { css } from "@emotion/core";

interface Props {
  style?: CSSProperties;
}

export const HalfRed = (props: PropsWithChildren<Props>) => (
  <div css={centerCss} style={props.style}>
    <div css={leftCss}>
      <div css={carouselCss}>
        <Carousel autoplay>
          <div css={textCss} >Performance <br/> We help your business grow by delivering results!</div>
          <div css={textCss} >Global Reach <br/> Have a product / service you want to market</div>
          <div css={textCss} >Target Lead Generation</div>
        </Carousel>
      </div>
    </div>
    <div css={rightCss}>
      {props.children}
    </div>
  </div>
);

export default HalfRed;


const textCss = css`
  font-size:24px;
  color:white;
  text-align:center;
  margin-bottom:90px;
`;