import React from "react";
import { Radio } from "antd";
import { RadioGroupProps } from "antd/lib/radio";
import isEqual from "react-fast-compare";

/**
 * Checkbox group input
 *
 * @public
 */

const RadioGroupInput = (props: RadioGroupProps) => {
  const { value, ...rest } = props;
  return <Radio.Group value={value} {...rest} />;
};
export default React.memo(RadioGroupInput, isEqual);
