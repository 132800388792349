import { DataSource } from ".";
import { list, create, read, update, del } from "../api/badWords";
import { BadWord } from "../models/BadWord";
export const BadWordsDS: DataSource<BadWord> = {
  list,
  create,
  read,
  update,
  del,
};

export default BadWordsDS;
