import { load, IReq, QApi, qapi2Url, API } from "./index";
import { Field } from "../models/Field";

export const list = (
  campaignID: number | string,
  req?: any,
  api?: QApi
): Promise<Field[]> =>
  load(
    `campaigns/${campaignID}/fields${qapi2Url(api)}`,
    "GET",
    false,
    undefined,
    api
  );
export const getDefaults = (
  campaignID: number | string,
  req?: any,
  api?: QApi
): Promise<Field[]> =>
  load(
    `campaigns/${campaignID}/fields/defaults${qapi2Url(api)}`,
    "GET",
    false,
    undefined,
    api
  );
export const create = (
  campaignID: number | string,
  data: Field
): Promise<Field> => load(`campaigns/${campaignID}/fields`, "POST", true, data);
export const read = (campaignID: number | string, req: IReq): Promise<Field> =>
  load(`campaigns/${campaignID}/fields/${req.ID}`, "GET", true, undefined);
export const update = (
  campaignID: number | string,
  req: IReq,
  data: Field
): Promise<Field> =>
  load(`campaigns/${campaignID}/fields/${req.ID}`, "PUT", true, data);
export const del = (campaignID: number | string, req: IReq): Promise<Field> =>
  load(`campaigns/${campaignID}/fields/${req.ID}`, "DELETE", true, undefined);

export const add = (
  campaignID: number | string,
  data: number[]
): Promise<Field> =>
  load(`campaigns/${campaignID}/fields/add`, "POST", true, data);
export const getFieldsCsv = (
  campaignID: number | string,
  req?: any,
  api?: QApi
) =>
  window.location.assign(
    `${API}campaigns/${campaignID}/fields/csv${qapi2Url(api)}`
  );

export const updatePartial = (
  campaignID: number,  
  recID: any,
  data: Partial<Field>
): Promise<any> => load(`campaigns/${campaignID}/fields/${recID}`, "PATCH", true, data);
