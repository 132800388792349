export function findByAttr(array: any[], name: string, target: any): any {
  if (!(array && name && target !== undefined)) {
    return undefined;
  }
  for (let i = array.length - 1; i > -1; i--) {
    if (array[i][name] === target) {
      return array[i];
    }
  }
  return undefined;
}

export function includes(search: any[], set: any[]): boolean {
  if (!(search && set)) {
    return false;
  }
  for (let i = search.length - 1; i > -1; i--) {
    if (set.indexOf(search[i]) < 0) {
      return false;
    }
  }
  return true;
}

export function removeByAttr(array: any[], name: string, target: any) {
  if (!(array && name && target !== undefined)) {
    return undefined;
  }
  for (let i = array.length - 1; i > -1; i--) {
    if (array[i][name] === target) {
      return array.splice(i, 1);
    }
  }
  return undefined;
}

export function getUnique(arr: any[], comp: any) {
  const unique = arr
    .map((e: any) => e[comp])

    // store the keys of the unique objects
    .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter((e: any) => arr[e])
    .map((e: any) => arr[e]);

  return unique;
}

export function mergeFilters(...arrs: string[]) {
  const filters: Map<string, string> = new Map();
  for (let i = 0; i < arrs.length; i++) {
    for (let j = 0; i < arrs[i].length; j++) {
      const filter = arrs[i][j].split("=");
      filters.set(filter[0], filter[1]);
    }
  }
  const newFilters = [];
  return filters.forEach((value, key) => newFilters.push(`${key}=${value}`));
}