import { Modal, Button, ModalProps } from "antd";
import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { ModelProps } from "../AutoForm";
import { css } from "@emotion/core";

export interface Props<T> extends ModalProps {
  title?: string;
  options?: T[];
  columns?: Array<ModelProps<any>>;
  onChange?: (value: any) => void;
  value?: any;
  detail: any;
  preview: (value: any) => any;
}

export default function ModalButton<T>(props: Props<T>) {
  const [visible, setVisible] = React.useState(false);
  const [value, setValue] = React.useState();
  const { columns, options, onChange, ...modal } = props;

  const onClose = React.useCallback((e: any) => {
    setVisible(false);
  }, []);
  const onOK = React.useCallback((e: any) => {
    setVisible(false);
    onChange?.(value);
  }, [onChange, value]);

  const onDetail = React.useCallback(() => {
    setVisible(true);
  }, []);

  const detail = React.useMemo(
    () => React.cloneElement(props.detail, { value: props.value, setValue, ...props.detail.props }),
    [props.detail, props.value]);

  return <div css={containerCss}>
    <Modal
      {...modal}
      visible={visible}
      onOk={onOK}
      onCancel={onClose}
      destroyOnClose
      centered
    >
      {detail}
    </Modal>
    {props?.preview(props.value)}
    <Button type="dashed" icon={<PlusOutlined />} onClick={onDetail} size="small" />
  </div>;
}

const containerCss = css`
  text-align:left;
  min-height:32px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  padding:4px 11px;
`;