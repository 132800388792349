import { load, IReq, QApi, qapi2Url, API } from "./index";
import { InsertionOrder } from "../models/InsertionOrder";

export const list = (req?: any, api?: QApi): Promise<InsertionOrder[]> =>
  load(`insertionOrders${qapi2Url(api)}`, "GET", false, undefined, api);
export const create = (data: InsertionOrder): Promise<InsertionOrder> =>
  load(`insertionOrders`, "POST", true, data);
export const read = (req: IReq): Promise<InsertionOrder> =>
  load(`insertionOrders/${req.ID}`, "GET", true, undefined);
export const update = (
  req: IReq,
  data: InsertionOrder,
): Promise<InsertionOrder> =>
  load(`insertionOrders/${req.ID}`, "PUT", true, data);
export const del = (req: IReq): Promise<InsertionOrder> =>
  load(`insertionOrders/${req.ID}`, "DELETE", true, undefined);
export const view = (req: InsertionOrder, api?: QApi) =>
  window.open(`${API}public/insertionOrders/view/${req.UniqueID}`, "_blank");
