import validation from "../../utils/messages/validation";
import BaseModel from "./BaseModel";

export interface SuppressionList extends BaseModel {
  Name: string;
  Value: string;
  Description: string;
  Cron: string;
  Format: boolean;
  Headerline: boolean;
  CampaignID: number;
  Hidden: boolean;
}
export const SuppressionListProps = {
  Value: {
    rules: [validation.required, validation.minMax(4, 50)],
  },
  Type: {
    rules: [validation.required],
  },
  Name: {
    rules: [validation.required, validation.minMax(3, 50)],
  },
  Format: {
    rules: [validation.required],
  },
  Headerline: {
    rules: [validation.required],
  },
};

export enum SuppressionListType {
  PhoneList = 1,
  EmailList = 2,
  ZIPList = 3,
}
export enum SuppressionFileFormat {
  CSV = 1,
}

export const SuppressionListTypes = [
  { ID: 1, Name: "Phone List" },
  { ID: 2, Name: "Email List" },
  { ID: 3, Name: "ZIP Code List" },
];

export const SuppressionFileFormats = [
  { ID: 1, Name: "CSV" },
];