import React from "react";
import CheckboxGroup from "antd/lib/checkbox/Group";
import { CheckboxGroupProps } from "antd/lib/checkbox";
import isEqual from "react-fast-compare";

/**
 * Checkbox group input
 *
 * @public
 */

const CheckGroupInput = (props: CheckboxGroupProps) => {
  const { value, ...rest } = props;
  return <CheckboxGroup value={value} {...rest} />;
};
export default React.memo(CheckGroupInput, isEqual);
