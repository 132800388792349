import validation from "../../utils/messages/validation";
import BaseModel from "./BaseModel";

export interface Tag extends BaseModel {
  Text: string;
  CreatorID: number;
  CreatorName: string;
}

export const TagProps = {
  Text: {
    rules: [validation.required, validation.minMax(2, 30)],
  },
};
