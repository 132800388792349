import BaseModel from "./BaseModel";
import { Buyer } from "./Buyer";
import { Campaign } from "./Campaign";
import { LeadOrder } from "./LeadOrder";
import { Publisher } from "./Publisher";

export interface Lead extends BaseModel {
  Data: string;
  SystemError: string;
  DataError: string;
  CampaignID: number;
  Campaign?: Campaign;
  PublisherID: number;
  LeadOrderID: number;
  Publisher?: Publisher;
  BuyerID: number;
  Buyer?: Buyer;
  Status: LeadStatus;
  ReturnUpload: boolean;
  ReturnComments: string;
  BuyerReturnDate: string;
  BuyerReturnReason: string;
  BuyerReturnStatus: LeadReturnStatus;
  PublisherReturnDate: string;
  PublisherReturnStatus: LeadReturnStatus;
  AuditDate: string;
  AuditedBy: string;
  LeadOrder?: LeadOrder;
  ExternalRef?: string;
}

export enum LeadStatus {
  Fresh = 1,
  // FieldsFail Fields controls failed
  FieldsFail,
  // ValidationFail Validation controls failed
  ValidationFail,
  // FieldsSuccess Fields controls passed
  FieldsSuccess,
  // ValidationSuccess Validation controls passed
  ValidationSuccess,
  // Posted Posted to a buyer
  Posted,
  // Unsold is Not sold, because of Buyer responses or errors or no buyer is compatible
  Unsold
}

//DON'T CHANGE THE ORDER
//it can effect the defaultfilters on BuyerReturn and Publisher Return Page
export enum LeadReturnStatus {
  Pending = 0,
  Accepted,
  Rejected,
};
export enum ReturnProcess {
  //Publisher
  Publisher = 1,
  Buyer,
  Both,
  RejectPublisher,
  RejectBuyer,
  AcceptPublisher,
  AcceptBuyer
};

export const LeadStatuses = [
  { ID: LeadStatus.FieldsFail, Name: "Field Error" },
  { ID: LeadStatus.ValidationFail, Name: "Validation Error" },
  { ID: LeadStatus.Posted, Name: "Posted", Color: "green" },
  { ID: LeadStatus.Unsold, Name: "Unsold", Color: "cyan" },
];
export const LeadReturnProcessArray = [
  { ID: ReturnProcess.Both, Name: "Return to Both Publisher and Buyer" },
  { ID: ReturnProcess.Publisher, Name: "Return to Publisher Only" },
  { ID: ReturnProcess.Buyer, Name: "Return to Buyer Only" },
];
export const RejectedLeadStatusArray = [
  { ID: LeadStatus.FieldsFail, Name: "Field Error", Color: "magenta" },
  { ID: LeadStatus.ValidationFail, Name: "Validation Error", Color: "red" },
];

export const BuyerReturnProcessArray = [
  { ID: ReturnProcess.AcceptBuyer, Name: "Accepted", Color: "lime" },
  { ID: ReturnProcess.RejectBuyer, Name: "Rejected", Color: "volcano" },
];
export const PublisherReturnProcessArray = [
  { ID: ReturnProcess.AcceptPublisher, Name: "Accepted", Color: "lime" },
  { ID: ReturnProcess.RejectPublisher, Name: "Rejected", Color: "volcano" },
];
export const ReturnStatusArray = [
  { ID: LeadReturnStatus.Pending, Name: "Pending Audit", Color: "blue" },
  { ID: LeadReturnStatus.Accepted, Name: "Accepted", Color: "lime" },
  { ID: LeadReturnStatus.Rejected, Name: "Rejected", Color: "volcano" },
];
