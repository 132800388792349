import { DataSource } from ".";
import { list, create, read, update, del } from "../api/roles";
import { Role } from "../models/Role";
export const RolesDS: DataSource<Role> = {
  list,
  create,
  read,
  update,
  del,
};

export default RolesDS;
