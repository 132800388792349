import React from "react";

import { ColumnProps } from "antd/lib/table";
import { FormInstance, FormProps } from "antd/lib/form/Form";
import { ColProps } from "antd/lib/col";
import { Form, Row } from "antd";
import { getWithFlatKey, setWithFlatKey } from "../../utils/collections/maps";
import FormItem from "./FormItem";
import { isEqual } from "../../utils/collections/equals";
import { css } from "@emotion/core";
import { InputType } from "./inputs";

export interface ModelProps<T> extends ColumnProps<T> {
  inputType: InputType;
  inputProps?: any;
  searchable?: boolean | "detail";
  visibleColumn?: boolean;
  useValueAsFilter?: boolean;
  fieldDecoratorOptions?: { [key: string]: any; };
  editable?: boolean;
}

interface Props<T> extends FormProps {
  model?: Array<ModelProps<T>>;
  data?: any;
  submitIfValid?: (values: T) => void;
  colProps?: ColProps;
  noValidation?: boolean;
}

const FORM_ITEM_LAYOUT = {
  labelCol: {
    xs: 24,
    sm: 10,
    md: 8,
    lg: 6
  },
  wrapperCol: {
    xs: 24,
    sm: 14,
    md: 16,
    lg: 18,
  },
};

export class AutoForm extends React.Component<Props<any>, any> {
  public formRef = React.createRef<FormInstance>();

  public componentDidMount() {
    this.updateForm();
  }

  public componentDidUpdate(pp: Props<any>, ps: any) {
    if (!isEqual(pp.data, this.props.data)) {
      this.updateForm();
    }
  }

  public render() {
    const { model, children, ...formProps } = this.props;
    const items = (model || []).filter(
      (i: ModelProps<any>) => i.dataIndex && i.inputType !== "ignore",
    );
    const fields = [];
    let idField;
    for (const item of items) {
      if (item.dataIndex === "ID") {
        idField = <FormItem
          key={item.title?.toString() ?? item.dataIndex?.toString()}
          item={item}
          fieldDecoratorOptions={item.fieldDecoratorOptions}
        />;
      } else {
        fields.push(<FormItem
          key={item.title?.toString() ?? item.dataIndex?.toString()}
          item={item}
          defaultColProps={this.props.colProps || { xs: 24 }}
          defaultFormLayout={this.props.labelCol ? { labelCol: this.props.labelCol, wrapperCol: this.props.wrapperCol } : FORM_ITEM_LAYOUT}
          fieldDecoratorOptions={item.fieldDecoratorOptions}
        />);
      }
    }
    return (
      <Form
        autoComplete="off"
        onFinish={this.submitIfValid}
        ref={this.formRef}
        style={{ position: "relative" }}
        {...formProps}
      >
        <Row>{fields}</Row>
        {children}
        <div css={idCss}>{idField}</div>
      </Form>
    );
  }

  private updateForm = () => {
    const { data, model } = this.props;
    this.formRef.current?.resetFields();
    const newdata: any = {};
    if (model && data) {
      model.forEach((i: ModelProps<any>) => {
        if (i.dataIndex)
          newdata[String(i.dataIndex)] = getWithFlatKey(
            data,
            String(i.dataIndex),
          );
      });
      this.formRef.current?.setFieldsValue(newdata);
    }
  };

  private submitIfValid = (values: any) => {
    if (this.props.model) {
      this.props.model.forEach((i: ModelProps<any>) => {
        if (
          i.inputType === "hidden" &&
          i.inputProps &&
          i.inputProps.clone &&
          i.dataIndex
        ) {
          setWithFlatKey(
            values,
            String(i.dataIndex),
            getWithFlatKey(values, i.inputProps.clone),
          );
        } else if (i.inputType === "infoignore" && i.dataIndex) {
          const key = String(i.dataIndex).split(".")[0];
          delete values[key];
        }
      });
    }
    if (this.props.submitIfValid) {
      this.props.submitIfValid(values);
    }
  };

  public validateFields = () => this.formRef.current?.validateFields();
}

export default AutoForm;

const idCss = css`
  position: absolute;
  left: 0px;
  bottom: -90px;
  float:left;
`;