import validation from "../../utils/messages/validation";
import BaseModel from "./BaseModel";

export interface EmailTemplate extends BaseModel {
  Name: string;
  Description: string;
  Subject: string;
  Body: string;
}
export const EmailTemplateProps = {
  Name: {
    rules: [validation.required, validation.minMax(5, 50)],
  },
  Description: {
    rules: [validation.required, validation.minMax(5, 100)],
  },
  Subject: {
    rules: [validation.required, validation.minMax(5, 200)],
  },
  Body: {
    rules: [validation.required, validation.minMax(20, 30000)],
  },
};
