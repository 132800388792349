import { DataSource } from ".";
import { list, create, read, update, del } from "../api/suppressionLists";
import { SuppressionList } from "../models/SuppressionList";
export const SuppressionListsDS: DataSource<SuppressionList> = {
  list,
  create,
  read,
  update,
  del,
};

export default SuppressionListsDS;
