import { load, IReq, QApi, qapi2Url } from "./index";
import { Validation } from "../models/Validation";

export const list = (campaignID: number | string, req?: any, api?: QApi): Promise<Validation[]> =>
  load(`campaigns/${campaignID}/validations${qapi2Url(api)}`, "GET", false, undefined, api);
export const create = (campaignID: number | string, data: Validation): Promise<Validation> =>
  load(`campaigns/${campaignID}/validations`, "POST", true, data);
  export const read = (campaignID: number | string, req: IReq): Promise<Validation> =>
  load(`campaigns/${campaignID}/validations/${req.ID}`, "GET", true, undefined);
  export const update = (campaignID: number | string, req: IReq, data: Validation): Promise<Validation> =>
  load(`campaigns/${campaignID}/validations/${req.ID}`, "PUT", true, data);
  export const del = (campaignID: number | string, req: IReq): Promise<Validation> =>
  load(`campaigns/${campaignID}/validations/${req.ID}`, "DELETE", true, undefined);
  
  export const add = (campaignID: number | string, data: number[]): Promise<Validation> =>
    load(`campaigns/${campaignID}/validations/add`, "POST", true, data);