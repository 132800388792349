export function getWithFlatKey(map: object, flatKey: string): any {
  const keys = flatKey.split(".");

  let val: string | any = map;
  for (const key of keys) {
    if (typeof val !== "object" || val === undefined || val === null) {
      return undefined;
    }
    val = val[key];
  }
  return val;
}

export function getFlatKeys(mapTemp: object): string[] {
  const flat: string[] = [];
  let map: any = mapTemp;
  const keys = Object.keys(map ?? {});
  for (const key of keys) {
    const val = map[key];
    if (typeof val === "object" && !val._isAMomentObject) {
      const innerKeys = getFlatKeys(val);
      for (const innerKey of innerKeys) {
        flat.push(`${key}.${innerKey}`);
      }
    } else {
      flat.push(key);
    }
  }
  return flat;
}

export function setWithFlatKey(
  map: object,
  flatKey: string | number,
  value: any,
) {
  if (typeof flatKey === "number") {
    flatKey = String(flatKey);
  }
  const keys = flatKey.split(".");
  let val: any = map;
  for (const key of keys) {
    if (typeof val !== "object" || val === undefined || val === null) {
      return;
    }
    if (key === keys[keys.length - 1]) {
      val[key] = value;
      break;
    }
    val = val[key];
  }
}
