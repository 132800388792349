import { DataSource } from ".";
import { list, create, read, update, del } from "../api/countries";
import { Country } from "../models/Country";
export const CountriesDS: DataSource<Country> = {
  list,
  create,
  read,
  update,
  del,
};

export default CountriesDS;
