import { load, IReq, QApi, qapi2Url } from "./index";
import { IPGeoRequest } from "../models/IPGeoRequest";

export const list = (req?: any, api?: QApi): Promise<IPGeoRequest[]> =>
  load(`ipGeoRequests${qapi2Url(api)}`, "GET", false, undefined, api);
export const create = (data: IPGeoRequest): Promise<IPGeoRequest> =>
  load(`ipGeoRequests`, "POST", true, data);
export const read = (req: IReq): Promise<IPGeoRequest> =>
  load(`ipGeoRequests/${req.ID}`, "GET", true, undefined);
export const resend = (req: IReq): Promise<IPGeoRequest> =>
  load(`ipGeoRequests/${req.ID}/resend`, "PUT", true, undefined);
