import { DataSource } from ".";
import { list, create, read, update, del } from "../api/insertionOrders";
import { InsertionOrder } from "../models/InsertionOrder";
export const InsertionOrdersDS: DataSource<InsertionOrder> = {
  list,
  create,
  read,
  update,
  del,
};

export default InsertionOrdersDS;
