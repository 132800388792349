
import { DataSource } from ".";
import { getActivities as list } from "../api/buyers";
import { ActivityLog } from "../models/ActivityLog";

export const BuyerActivityDS = (buyerID: string | number):  DataSource<ActivityLog>  => ({
  list: list.bind(undefined,buyerID),
  create: () => { throw "Not implemented"; }, //eslint-disable-line
  update: () => { throw "Not implemented"; }, //eslint-disable-line
  del: () => { throw "Not implemented"; }, //eslint-disable-line
  read: () => { throw "Not implemented"; }, //eslint-disable-line
});


export default BuyerActivityDS;
