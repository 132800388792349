import { load, IReq, QApi, qapi2Url } from "./index";
import { LeadOrder } from "../models/LeadOrder";
import { MappingField } from "../models/MappingField";
import { Publisher } from "../models/Publisher";

export const list = (req?: any, api?: QApi): Promise<LeadOrder[]> =>
  load(`leadOrders${qapi2Url(api)}`, "GET", false, undefined, api);
export const create = (data: LeadOrder): Promise<LeadOrder> =>
  load(`leadOrders`, "POST", true, data);
export const read = (req: IReq): Promise<LeadOrder> =>
  load(`leadOrders/${req.ID}`, "GET", true, undefined);
export const update = (req: IReq, data: LeadOrder): Promise<LeadOrder> =>
  load(`leadOrders/${req.ID}`, "PUT", true, data);
export const del = (req: IReq): Promise<LeadOrder> =>
  load(`leadOrders/${req.ID}`, "DELETE", true, undefined);

export const saveMappingFields = (
  leadOrderID: number | string,
  data: LeadOrder,
): Promise<LeadOrder> =>
  load(`leadOrders/${leadOrderID}/saveMappingFields`, "POST", true, data);

export const getMappingFields = (req: IReq): Promise<MappingField[]> =>
  load(`leadOrders/${req.ID}/mappingFields`, "GET", true, undefined);
export const getBlockedPublishers = (req: IReq): Promise<Publisher[]> =>
  load(`leadOrders/${req.ID}/blockedPublishers`, "GET", true, undefined);
export const copy = (req: IReq, data: LeadOrder): Promise<LeadOrder> =>
  load(`leadOrders/${req.ID}/copy`, "POST", true, data);

export const leadOrderStatus = (req?: any, api?: QApi): Promise<any> =>
  load(`leadOrders/status${qapi2Url(api)}`, "GET", false, undefined, api);
