import React from "react";
import { ModelProps } from "./AutoForm";
import Col, { ColProps } from "antd/lib/col";
import { Divider, Form, Input } from "antd";
import NumberInput from "./inputs/NumberInput";
import MaskedInput from "./inputs/MaskedInput";
import CheckInput from "./inputs/CheckInput";
import DateInput from "./inputs/DateInput";
import SelectInput from "./inputs/SelectInput";
import CheckGroupInput from "./inputs/CheckGroupInput";
import FormButton from "./FormButton";
import UploadInput from "./inputs/UploadInput";
import FieldInfo from "./FieldInfo";
import CheckTable from "./inputs/CheckTable";
import TagInput from "./inputs/TagInput";
import CheckTableWithinModal from "./inputs/CheckTableWithinModal";
import RadioGroupInput from "./inputs/RadioGroupInput";
import ModalButton from "./inputs/ModalButton";
import Description from "./Description";
interface Props {
  item: ModelProps<any>;
  colProps?: ColProps;
  defaultColProps?: ColProps;
  defaultFormLayout?: any;
  noValidation?: boolean;
  fieldDecoratorOptions?: { [key: string]: any; };
}

const FormItem = (props: Props) => {
  const {
    item,
    colProps,
    defaultColProps,
    noValidation,
    defaultFormLayout,
    fieldDecoratorOptions,
  } = props;
  const dataIndex = item.dataIndex;
  const inputProps = item.inputProps;

  switch (item.inputType) {

    case "divider":
      return (
        <Divider key={dataIndex} {...inputProps}>
          {item.title}
        </Divider>
      );
  }

  const InputComp = decideInput(item);

  if (item.inputType === "select") {
    inputProps.filterOption = (input: any, option: any) => {
      return (
        option.props.children
          .toLocaleLowerCase("en-US")
          .indexOf(input.toLocaleLowerCase("en-US")) >= 0
      );
    };
    inputProps.optionFilterProp = "Name";
    inputProps.showSearch = true;
  }

  const columnProps = colProps || defaultColProps;

  const title =
    typeof item.title === "string" ? item.title : (item as any).label;
  const fiStyle = noValidation === true ? { marginBottom: 0 } : undefined;
  return (
    <Col {...columnProps} key={String(dataIndex)}>
      <Form.Item
        {...defaultFormLayout}
        {...fieldDecoratorOptions}
        label={title}
        key={dataIndex}
        name={dataIndex}
        hidden={item.inputType === "hidden"}
        style={fiStyle}
        {...(item.inputType === "checkbox"
          ? { valuePropName: "checked" }
          : {})}
        initialValue={item.inputProps?.initialValue}
      >
        <InputComp {...inputProps} />
      </Form.Item>
    </Col>
  );
};

export default React.memo(FormItem);

function decideInput(model: ModelProps<any>): any {
  switch (model.inputType) {
    case "text":
      return Input;
    case "number":
      return NumberInput;
    case "textarea":
      return Input.TextArea;
    case "masked":
      return MaskedInput;
    case "checkbox":
      return CheckInput;
    case "checktable":
      return CheckTable;
    case "datepicker":
      return DateInput;
    case "select":
      return SelectInput;
    case "divider":
      return Divider;
    case "checkboxgroup":
      return CheckGroupInput;
    case "button":
      return FormButton;
    case "upload":
      return UploadInput;
    case "info":
      return FieldInfo;
    case "infoignore":
      return FieldInfo;
    case "tag":
      return TagInput;
    case "checkTableWithinModal":
      return CheckTableWithinModal;
    case "radio":
      return RadioGroupInput;
    case "modal":
      return ModalButton;
    case "description":
      return Description;
    default:
      console.warn(
        "Can't find any component for the input type",
        model.inputType,
      );
  }
  return Input;
}
