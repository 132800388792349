import validation from "../../utils/messages/validation";
import BaseModel from "./BaseModel";

export interface Category extends BaseModel {
  Name: string;
  Children?: Category[];
  ParentID: number;
  CreatorID: number;
  CreatorName: string;
}
export const CategoryProps = {
  Name: {
    rules: [validation.required, validation.minMax(3, 30)],
  },
};
