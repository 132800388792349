import React, { useCallback, useContext } from "react";
import Select, { SelectProps, SelectValue } from "antd/lib/select";
import { getWithFlatKey } from "../../../utils/collections/maps";
import { isDefined } from "../../../utils/collections/typechecks";
import { LanguageContext } from "../../../contexts/LanguageContext";
import isEqual from "react-fast-compare";

interface Props extends SelectProps<SelectValue> {
  options?: any[];
  optionsAsync?: () => any[];
  valueField?: string;
  labelField?: string;
  disabledFields?: string[];
  groupBy?: string;
}

const SelectInput = (props: Props) => {
  const {
    options,
    optionsAsync,
    valueField,
    labelField,
    disabledFields,
    groupBy,
    ...rest
  } = props;
  let children = null;
  const languageContext: any = useContext(LanguageContext);

  const renderOption = useCallback(
    (option: any) => {
      const value = getValue(option, valueField);
      const label = getLabel(option, labelField);
      const isDisabled = disabledFields?.includes(value);
      return (
        //TODO: key={label} causes some issues about same key duplication. Can make key={value} but is it needs some changes on pages?
        <Select.Option key={value} value={value} disabled={isDisabled}>
          {label}
        </Select.Option>
      );
    },
    [valueField, labelField, disabledFields],
  );

  const renderGroup = useCallback(
    (options: any[]) => {
      const grouped: any = {};
      options.forEach((option: any) => {
        const value = getValue(option, valueField);
        const group = getWithFlatKey(option, groupBy || "");
        const label = getLabel(option, labelField);
        if (!isDefined(grouped[group])) grouped[group] = [];
        grouped[group].push(
          <Select.Option key={value} value={value}>
            {label}
          </Select.Option>,
        );
      });
      const keys = Object.keys(grouped);
      return keys.map((key: any) => {
        return (
          <Select.OptGroup label={key} key={key}>
            {grouped[key]}
          </Select.OptGroup>
        );
      });
    },
    [valueField, labelField, groupBy],
  );
  const filterOption = useCallback((input: any, option: any) => {
    return option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
      || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
       //for optgroup search
       || option?.children?.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
  }, []);

  if (options) {
    children = groupBy ? renderGroup(options) : options.map(renderOption);
  } else if (optionsAsync) {
    const resp = optionsAsync();
    children = resp && (groupBy ? renderGroup(resp) : resp.map(renderOption));
  }

  return (
    <Select {...rest} filterOption={props.filterOption ? props.filterOption : filterOption} notFoundContent={languageContext.noRecords}>
      {children}
    </Select>
  );
};

export default React.memo(SelectInput, isEqual);

function getLabel(option: any, labelField?: string) {
  if (typeof option === "string") {
    return option;
  }
  return labelField
    ? labelField
      .split(",")
      .map((key: string) => getWithFlatKey(option, key))
      .join(" ")
    : JSON.stringify(option);
}
function getValue(option: any, valueField?: string) {
  if (typeof option === "string") {
    return option;
  }
  return valueField
    ? getWithFlatKey(option, valueField)
    : JSON.stringify(option);
}
