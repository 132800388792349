import React from "react";
import { Checkbox } from "antd";
import { CheckboxProps } from "antd/lib/checkbox";

/**
 * Checkbox input
 *
 * @public
 */

const CheckInput = (props: CheckboxProps & { tristate?: boolean; }) => {
  const { value, tristate, ...rest } = props;
  let indeterminate = tristate && (props.checked === undefined);
  //TODO: do we need value ?
  // let checked = value ?? props.checked; 
  
  return <Checkbox  {...rest}  indeterminate={indeterminate} />;
};

export default CheckInput;
