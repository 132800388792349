export enum FieldType {
    String = 1,
    Number,
    Boolean,
    Date
}

export const FieldTypeArray = [
    { ID: 1, Name: "String" },
    { ID: 2, Name: "Number" },
    { ID: 3, Name: "Boolean" },
    { ID: 4, Name: "Date" },
];
