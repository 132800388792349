import React, { useCallback, useEffect, useRef, useState } from "react";
import { Tag, Input } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import { PlusOutlined } from "@ant-design/icons";
import { TagProps } from "antd/lib/tag";

interface Props extends TagProps {
  onChange?: (value: any) => void;
  value?: string;
}

const TagInput = (props: Props) => {
  const { value } = props;
  const [tags, setTags] = useState<string[]>([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const tagInput: any = useRef();

  useEffect(() => {
    if (value) {
      const parsedArr: string[] = typeof value === "string" ? JSON.parse(value) : value;
      setTags(parsedArr);
    }
  }, [value]);

  useEffect(() => {
    if (inputVisible) {
      tagInput?.current?.focus();
    }
  }, [inputVisible]);

  const showInput = useCallback(() => {
    setInputVisible(true);
  }, []);

  const handleTagChange = useCallback(
    (tagArr: string[]) => {
      const strTagArr = JSON.stringify(tagArr);
      if (props.onChange) {
        props.onChange(strTagArr);
      }
    },
    [props],
  );

  const handleDelete = useCallback(
    (removedTag: string) => {
      const currentTags = tags.filter((tag: string) => tag !== removedTag);
      setTags(currentTags);
      handleTagChange(currentTags);
    },
    [handleTagChange, tags],
  );

  const handleInputChange = useCallback((e: any) => {
    setInputValue(e.target.value);
  }, []);

  const handleInputConfirm = useCallback(() => {
    let tagArr: string[] = tags;
    if (inputValue && tagArr.indexOf(inputValue) === -1) {
      tagArr = [...tags, inputValue];
    }
    setTags(tagArr);
    setInputValue("");
    setInputVisible(false);
    handleTagChange(tagArr);
  }, [handleTagChange, tags, inputValue]);

  const renderTag = useCallback(
    (tag: string) => {
      const tagElem = (
        <Tag
          closable
          onClose={(e: any) => {
            e.preventDefault();
            handleDelete(tag);
          }}
        >
          {tag}
        </Tag>
      );

      return (
        <span key={tag} style={{ display: "inline-block", margin: 1.5 }}>
          {tagElem}
        </span>
      );
    },
    [handleDelete],
  );

  return (
    <React.Fragment>
      <TweenOneGroup
        enter={{
          scale: 0.8,
          opacity: 0,
          type: "from",
          duration: 100,
        }}
        leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
        appear={false}
      >
        {tags.map(renderTag)}
      </TweenOneGroup>
      {inputVisible && (
        <Input
          ref={tagInput}
          type="text"
          size="small"
          style={{ width: 78 }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && (
        <Tag onClick={showInput}>
          <PlusOutlined /> Add
        </Tag>
      )}
    </React.Fragment>
  );
};

export default TagInput;
