import { DataSource } from ".";
import { list } from "../api/campaignLeadOrders";
import { LeadOrder } from "../models/LeadOrder";

export const CampaignLeadOrdersDS = (campaignID: string | number): DataSource<LeadOrder> => ({
  list: list.bind(undefined, campaignID),
  create: () => { throw "Not implemented"; }, //eslint-disable-line
  update: () => { throw "Not implemented"; }, //eslint-disable-line
  del: () => { throw "Not implemented"; }, //eslint-disable-line
  read: () => { throw "Not implemented"; }, //eslint-disable-line
});


export default CampaignLeadOrdersDS;
